import React, { useRef, useState, useEffect } from 'react';
import { apiCall } from '../helpers/axios';
import * as styles from './index.module.scss';
import LandingSection from '../components/home-sections/landing-section';
import WhatWeDoSection from '../components/home-sections/what-we-do-section';
import CaseStudiesSection from '../components/home-sections/case-studies-section';
import OurClientsSection from '../components/home-sections/our-clients-section';
import LandingSectionMobile from '../components/home-sections/landing-section/mobile';
import WhatWeDoSectionMobile from '../components/home-sections/what-we-do-section/mobile';
import CaseStudiesSectionMobile from '../components/home-sections/case-studies-section/mobile';
import OurClientsSectionMobile from '../components/home-sections/our-clients-section/mobile';
import FooterSection from '../components/home-sections/footer-section';
import Footer from '../components/footer';
import ScrollDownIndicator from '../components/scroll-down-indicator';
import { graphql } from 'gatsby';
import Layout from '../layouts/home';
import { setLandingIndex } from '../state/home';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { setCaseStudies } from '../state/caseStudies';
import { getCaseStudies } from '../helpers/axios';
import { getTestimonials } from '../helpers/axios';
import { setTestimonials } from '../state/testimonials';
import axios from 'axios';
import SEO from '../components/seo';
import CookieConsent from 'react-cookie-consent';
import useImagePreloader from '../hooks/useImagePreloader';
import Preloader from '../components/preloader';

const HomePage = ({ data, transitionStatus }) => {
  // ========= REFS =========== //
  const topNavRef = useRef();
  const sideNavRef = useRef();
  const scrollIndicatorRef = useRef();
  const previousSection = useRef(0);
  const activeTransitions = useSelector((state) => state.nav.activeTransitions);
  const testimonials = useSelector(
    (state) => state.testimonials.testimonials || []
  );
  const canTransition = activeTransitions.length === 0;
  const [currentSection, setCurrentSection] = useState(0); // handles which section to move to
  // const currentSection = useRef(0);
  const [sectionParallaxDirection, setSectionParallaxDirection] = useState('up');
  const dispatch = useDispatch();
  const breakpoints = useBreakpoint();

  const caseStudies = useSelector((state) => state.caseStudies.caseStudies);

  const menuOpen = useSelector((state) => state.ui.menuOpen || false);

  const imagePreloader = useImagePreloader(
    caseStudies
      .filter((cs) => cs.attributes.featured)
      .map((cs) => cs.attributes.featureImage.data.attributes.url)
  );

  const fetchTestimonials = async () => {
    const response = await getTestimonials();
    const testimonialsData = await Promise.all(
      response.data.map(async (t) => ({
        brandColour: t.attributes.brandColor,
        space: t.attributes.case_study_category.data
          ? t.attributes.case_study_category.data.attributes.title
          : t.attributes.clientName,
        logo: await axios
          .get(t.attributes.logo.data.attributes.url)
          .then((res) => res.data),
        name: t.attributes.clientName,
        testimony: t.attributes.testimonial || '',
      }))
    );
    dispatch(setTestimonials(testimonialsData));
  };

  const fetchCaseStudies = async () => {
    const response = await getCaseStudies();
    dispatch(setCaseStudies(response.data));
  };

  useEffect(() => {
    if (caseStudies.length > 0) {
      imagePreloader.startPreloading();
    }
  }, [caseStudies]);

  useEffect(() => {
    fetchCaseStudies();
    fetchTestimonials();
  }, []);

  // at a later stage other sections will have inner indexes to also set from each respective section
  useEffect(() => {
    dispatch(setLandingIndex(0));

    return () => {
      // when leaving the home page, set the landing index to 0
      dispatch(setLandingIndex(0));
    };
  }, []);

  useEffect(() => {}, [activeTransitions]);

  const updateSection = (index) => {
    //determine animation direction

    setSectionParallaxDirection(index > previousSection.current ? 'up' : 'down');
    // previousSection.current = currentSection; // keeps track of previous section to determine direction
    setCurrentSection(index);
  };

  const goToNextSection = () => {
    if (canTransition && currentSection + 1 < 5) {
      updateSection(currentSection + 1);
    }
  };

  const goToPreviousSection = () => {
    if (canTransition && currentSection - 1 >= 0) {
      updateSection(currentSection - 1);
    }
  };

  useEffect(() => {
    previousSection.current = currentSection;
  }, [currentSection]);

  // ============ DATA ============ //

  const cookieContainerStyles = {
    left: '50%',
    width: '90%',
    maxWidth: '1130px',
    alignItems: 'center',
    transform: 'translate(-50%, 20px)',
    background: 'rgba(239, 239, 241, 0.9)',
    borderRadius: '10px',
    padding: '10px',
    paddingLeft: '20px',
  };

  const cookieContainerMobileStyle = {
    left: '50%',
    width: '90%',
    maxWidth: '1130px',
    alignItems: 'center',
    transform: 'translate(-50%, -20px)',
    background: 'rgba(239, 239, 241, 0.9)',
    borderRadius: '10px',
    padding: '10px',
    paddingLeft: '20px',
  };

  const cookieContentStyle = {
    margin: 0,
    display: 'flex',
    borderRight: '1px dashed #76747e',
    marginRight: '25px',
    paddingRight: '25px',
  };

  const cookieContentMobileStyle = {
    margin: 0,
    display: 'flex',
    flexWrap:'wrap',
    marginRight: '25px',
    paddingRight: '25px',
  };

  const cookieAllowButtonStyle = {
    background: '#2E2F36',
    border: '2px solid transparent',
    borderRadius: '5px',
    boxShadow: 'none',
    cursor: 'pointer',
    margin: '5px',
    fontFamily: 'Termina',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '100%',
    padding: '16px 22px',
    color: 'white',
  };

  const cookieDeclineButtonStyle = {
    background: 'rgba(255,255,255,1)',
    border: '2px solid #2E2F36',
    borderRadius: '5px',
    boxShadow: 'none',
    cursor: 'pointer',
    margin: '5px',
    fontFamily: 'Termina',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '100%',
    padding: '16px 22px',
    color: '#2E2F36',
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <SEO />
      {imagePreloader.isLoading && <Preloader />}
      <Layout name={'home'} hideLogo={!breakpoints.sm}>
        {!breakpoints.sm ? (
          <>
            <CookieConsent
              cookieName='LuckyBeardCookie'
              location='top'
              style={cookieContainerStyles}
              contentStyle={cookieContentStyle}
              buttonText='Allow'
              buttonStyle={cookieAllowButtonStyle}
              enableDeclineButton={true}
              declineButtonText='Decline'
              declineButtonStyle={cookieDeclineButtonStyle}
              expires={150}
            >
              <h4 className={`title-XS ${styles.cookieH4}`}>Allow cookies</h4>
              <p className={`body-XS ${styles.cookieP}`}>
                When you Allow cookies, that means you agree to the storing of
                cookies on your device for the purpose of enhancing site
                navigation, analyzing site usage, and assisting in our marketing
                efforts.
              </p>
            </CookieConsent>
            <LandingSection
              topNavRef={topNavRef}
              sideNavRef={sideNavRef}
              scrollIndicatorRef={scrollIndicatorRef}
              goToNextSection={goToNextSection}
              show={currentSection === 0}
              sectionParallaxDirection={sectionParallaxDirection}
            />

            <WhatWeDoSection
              goToNextSection={goToNextSection}
              goToPreviousSection={goToPreviousSection}
              show={currentSection === 1}
              sectionParallaxDirection={sectionParallaxDirection}
            />

            <OurClientsSection
              goToNextSection={goToNextSection}
              goToPreviousSection={goToPreviousSection}
              testimonies={testimonials}
              show={currentSection === 2}
              sectionParallaxDirection={sectionParallaxDirection}
            />

            <CaseStudiesSection
              goToNextSection={goToNextSection}
              goToPreviousSection={goToPreviousSection}
              featuredCaseStudies={
                caseStudies
                  ? caseStudies.filter((cs) => cs.attributes.featured)
                  : []
              }
              show={currentSection === 3}
              sectionParallaxDirection={sectionParallaxDirection}
            />

            <FooterSection
              goToPreviousSection={goToPreviousSection}
              show={currentSection === 4}
              sectionParallaxDirection={sectionParallaxDirection}
            />
          </>
        ) : (
          <div
            className={styles.mobileOnly}
            style={{ height: menuOpen ? '100vh' : 'auto' }}
          >
            <CookieConsent
              cookieName='LuckyBeardCookie'
              location='bottom'
              style={cookieContainerMobileStyle}
              contentStyle={cookieContentMobileStyle}
              buttonText='Allow'
              buttonStyle={cookieAllowButtonStyle}
              enableDeclineButton={true}
              declineButtonText='Decline'
              declineButtonStyle={cookieDeclineButtonStyle}
              expires={150}
            >
              <h4 className={`title-XS ${styles.cookieH4} ${styles.mobile}`}>Allow cookies</h4>
              <p className={`body-XS ${styles.cookieP}`}>
                When you Allow cookies, that means you agree to the storing of
                cookies on your device for the purpose of enhancing site
                navigation, analyzing site usage, and assisting in our marketing
                efforts.
              </p>
            </CookieConsent>
            <LandingSectionMobile />
            <WhatWeDoSectionMobile />
            <OurClientsSectionMobile testimonies={testimonials} />
            <CaseStudiesSectionMobile
              featuredCaseStudies={
                caseStudies
                  ? caseStudies.filter((cs) => cs.attributes.featured)
                  : []
              }
            />
            <Footer />
          </div>
        )}
      </Layout>
    </div>
  );
};

export default HomePage;
export const query = graphql`
  query MyQuery {
    allContentfulCaseStudy(sort: { order: ASC, fields: createdAt }) {
      edges {
        node {
          id
          category
          description {
            description
          }
          roadmap
          title
          weDid
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: updatedAt, order: ASC }) {
      edges {
        node {
          author
          updatedAt
          image {
            file {
              url
            }
          }
          title
          excerpt {
            excerpt
          }
        }
      }
    }
    allContentfulClient {
      edges {
        node {
          company
          logo {
            file {
              url
            }
          }
          space
        }
      }
    }
  }
`;
