// NEXT STEPS: add slide click interaction so that it can jump to that index

import React, { useEffect, useState, useRef } from 'react';
import * as styles from './index.module.scss';
import SloganMantra from '../../slogan-mantra';
import Dropdown from '../../dropdown';
import gsap from 'gsap';
import { TransitionGroup, Transition } from 'react-transition-group';
import { setOnOurClientsSection } from '../../../state/nav';
import { useDispatch, useSelector } from 'react-redux';
import { addTransition, removeTransition } from '../../../state/nav';
import useWheel from '../../../hooks/useWheel';
import useSwipe from '../../../hooks/useSwipe';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';
import { Samy, SvgProxy } from 'react-samy-svg';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
SwiperCore.use([Autoplay]);

const OurClientsSectionTransition = (props) => {
  const animDuration = 1;
  const { show, sectionParallaxDirection } = props;
  const dispatch = useDispatch();
  const activeTransitions = useSelector((state) => state.nav.activeTransitions);
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) =>
        sectionEnter(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('our-clients'));
          },
          onComplete: () => {
            dispatch(removeTransition('our-clients'));
          },
        })
      }
      onExit={(node) =>
        sectionExit(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('our-clients'));
          },
          onComplete: () => {
            dispatch(removeTransition('our-clients'));
          },
        })
      }
    >
      <OurClientsSection
        animDuration={animDuration}
        canTransition={activeTransitions.length === 0}
        {...props}
      />
    </Transition>
  );
};

const OurClientsSection = ({
  goToPreviousSection,
  goToNextSection,
  canTransition,
  animDuration,
  testimonies,
}) => {
  const [activeFilter, setActiveFilter] = useState('All');
  const spaces = [
    'All',
    ...Array.from(new Set(testimonies.map((t) => t.space))),
  ]; // get unique list of spaces
  const dispatch = useDispatch();
  const [filteredTestimonies, setFilteredTestimonies] = useState(testimonies);
  const localTransitionLocked = useRef(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef();
  const swiperInstance = useRef();
  useEffect(() => {
    dispatch(setOnOurClientsSection(true));
    const transitionLocked = setTimeout(() => {
      localTransitionLocked.current = false;
    }, animDuration * 1000 + 50);
    return () => {
      clearTimeout(transitionLocked);
      dispatch(setOnOurClientsSection(false));
    };
  }, []);

  useEffect(() => {
    swiperInstance.current.slideTo(0);
  }, [activeFilter]);

  const sliderSettings = {
    slidesPerView: 'auto',
    spaceBetween: 35,
    centeredSlides: true,
    grabCursor: true,
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    onSlideChange: (e) => {
      setActiveIndex(e.realIndex);
    },
  };

  const onFilterChange = (value) => {
    setActiveFilter(value);
  };

  useEffect(() => {
    setActiveFilter('All');
  }, []);

  useEffect(() => {
    setFilteredTestimonies(
      testimonies.filter((t, i) => {
        if (activeFilter === 'All') {
          return true;
        } else if (t.space === activeFilter) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [activeFilter]);

  const handleWheel = (event) => {
    if (!canTransition || localTransitionLocked.current) {
      return;
    }
    localTransitionLocked.current = true;
    if (event.deltaY > 0) {
      // scrolls down
      goToNextSection();
    } else {
      // scrolls up
      goToPreviousSection();
    }
  };

  const [onWheel] = useWheel(handleWheel, {
    threshold: 10,
    throttle: 1000,
    canTriggerCallback: () => canTransition && !localTransitionLocked.current,
  });

  const handleSwipe = (direction) => {
    if (!canTransition || localTransitionLocked.current) {
      return;
    }

    if (direction === 'up') {
      goToNextSection();
    } else {
      goToPreviousSection();
    }
  };

  const [touchStart, touchEnd] = useSwipe(handleSwipe, {
    threshold: 50,
    throttle: 1000,
    canTriggerCallback: () => canTransition && !localTransitionLocked.current,
  });

  const changeSlide = (slide) => {
    swiperInstance.current.slideTo(slide);
  };

  const offsetSlider = (swiper) => {
    gsap.to(swiperRef.current.firstChild, {
      marginLeft: swiper.slidesGrid[0],
      duration: 0.0001,
    });
  };
  // controls overall animation
  const timeoutDuration = 0.35;

  // these paramaters are automatically caluclated based on timeoutDuration,
  // you can tweak the percentage for animation if delay needs to be more
  const animationDuration = timeoutDuration * 0.7; // 70% animation, the rest is staggered delay
  const delayDuration = timeoutDuration - animationDuration; // calculates delay time
  return (
    <div
      onWheel={onWheel}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      className={styles.ourClientsWrapper}
    >
      <div className={styles.topContainer}>
        <div className={styles.gradientTextWrapper}>
          <p className={`title-M semi-bold ${styles.featureText}`}>
            {/* {' '}
            {filteredTestimonies[activeIndex] &&
              filteredTestimonies[activeIndex].testimony}{' '} */}
            The brands we've worked with
          </p>
        </div>

        <div className={styles.smWrapper}>
          <SloganMantra />
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.bottomContainer}>
        {/* <div className={styles.dropdownWrapper}> */}
        {/* TODO if it's on mobile, show dropdown else show list */}
        {/* <Dropdown options={spaces} onChange={onFilterChange} /> */}
        {/* TODO if it's on desktop show list */}
        {/* </div> */}
        <div className={`${styles.filterList}`}>
          {spaces.map((space, i) => (
            <div
              onClick={() => {
                onFilterChange(space);
              }}
              key={`space-${i}`}
              className={`${styles.filterOption} utility-overline-heavy ${
                activeFilter === space && styles.active
              }`}
            >
              {' '}
              {space}{' '}
            </div>
          ))}
        </div>
        <div className={styles.testimonyCarousel}>
          <div className={styles.swiperWrapper}>
            <Swiper
              onInit={(val) => {
                swiperInstance.current = val;
              }}
              onResize={offsetSlider}
              onSwiper={offsetSlider}
              ref={swiperRef}
              style={{ paddingLeft: 150, paddingRight: 150 }}
              {...sliderSettings}
            >
              {filteredTestimonies &&
                filteredTestimonies.map((t, i) => (
                  <SwiperSlide
                    key={t.name}
                    onClick={() => {
                      changeSlide(i);
                    }}
                    className={`${styles.swiperSlide}`}
                  >
                    <div
                      className={`${styles.testimonyBlockWrapper} ${
                        activeIndex === i && styles.active
                      } `}
                    >
                      <div
                        className={`${styles.testimonyBlock} ${
                          activeIndex === i && styles.active
                        }`}
                        style={{
                          background: t.brandColour,
                        }}
                      >
                        <div className={styles.logoWrapper}>
                          <Samy svgXML={t.logo}>
                            <SvgProxy
                              selector={'svg'}
                              width={'100%'}
                              height={'100%'}
                              style={{ maxWidth: 200 }}
                            />
                            <SvgProxy
                              selector={'path'}
                              fill={activeIndex === i ? '#FFF' : '#000'}
                            />
                          </Samy>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          <div className={`${styles.carouselPagination}`}>
            <div
              className={`${styles.paginationItem}`}
              style={{
                transform: `translateX(${
                  activeIndex * (315 / filteredTestimonies.length)
                }px)`,
                width: 315 / filteredTestimonies.length,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClientsSectionTransition;
