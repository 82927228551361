import React, { useRef, Suspense, useEffect } from 'react';
import * as styles from './index.module.scss';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import glsl from 'babel-plugin-glsl/macro';
import * as THREE from 'three';
import { shaderMaterial } from '@react-three/drei';
import gsap from 'gsap';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
const ColorShiftMaterial = shaderMaterial(
  {
    mouse: [0, 0],
    resolution: [1920, 1080],
    time: 0,
    uColor: new THREE.Color(0.0, 0.0, 0.0),
  },

  //vertex shaders
  glsl`
      varying vec2 vUv;
  
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`,

  //fragment shaders
  glsl`
      #ifdef GL_ES
      precision mediump float;
      #endif
      
      uniform vec2 resolution;
      uniform float time;
      uniform vec2 mouse;
      
      const float Pi = 3.14159;
      
      float sinApprox(float x) {
          x = Pi + (2.0 * Pi) * floor(x / (2.0 * Pi)) - x;
          return (3.0 / Pi) * x - (3.0 / Pi / Pi) * x * abs(x);
      }
      
      float cosApprox(float x) {
          return sinApprox(x + 0.5 * Pi);
      }
      
      void main()
      {
        vec2 p=(3.0*gl_FragCoord.xy-resolution)/max(resolution.x,resolution.y);
        for(int i=1;i<25;i++)
        {
          vec2 newp=p;
          newp.x+=0.75/float(i)*sin(float(i)*p.y+time/10.0+0.3*float(i))+mouse.y/10.0;		
          newp.y+=0.75/float(i)*sin(float(i)*p.x+time/10.0+0.3*float(i+10))-mouse.x/10.0+15.0;
          p=newp;
        }
        vec3 col=vec3(0.5*sin(3.0*p.x)+0.5,0.5*sin(3.0*p.y)+0.5,sin(p.x+p.y));
        gl_FragColor=vec4(col, 1.0);
      }
      `
);
extend({ ColorShiftMaterial });

const GlslPlane = () => {
  const ref = useRef();
  const { size } = useThree();

  // update shader with mouse coordinates
  const mouseMoved = (e) => {
    if (e !== null && ref.current !== null) {
      ref.current.mouse = [e.pageX / size.width, e.pageY / size.height];
    } else {
      // means ref is not set yet, and mouse event fired already, or ref has been cleaned up and mouse event is still firing later
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoved);
    return () => {
      document.removeEventListener('mousemove', mouseMoved);
    };
  }, []);

  useFrame(({ clock }) => {
    ref.current.time = clock.getElapsedTime();
    ref.current.resolution = [size.width, size.height];
  });

  return (
    <mesh>
      <planeBufferGeometry args={[3, 1.3]} />
      <colorShiftMaterial ref={ref} />
    </mesh>
  );
};

const SVGFilter = React.forwardRef((_, ref) => {
  const pathRef = useRef();
  const breakpoints = useBreakpoint();
  let svgPathX = '350px';

  if (breakpoints.l) {
    svgPathX = '150px';
  }

  if (breakpoints.md) {
    svgPathX = '0px';
  }

  if (breakpoints.sm) {
    svgPathX = '150px';
  }

  if (breakpoints.xs) {
    svgPathX = '100px';
  }

  useEffect(() => {
    // sets height and width of svg path
    if (!breakpoints.md) {
      gsap.set(pathRef.current, {
        attr: {
          height: window.innerHeight * 1.007, // add 1% of view height to height
          width: 0.58980044345898 * window.innerHeight * 1.007,
        },
      });
    }
  }, []);

  return (
    <div className={styles.maskContainer}>
      <svg
        ref={ref}
        xmlns='http://www.w3.org/2000/svg'
        className='art'
        width='100%'
        height='100%'
      >
        <defs>
          <mask id='mask'>
            <rect x='0' y='0' width='100vw' height='100%' fill='white' />

            <svg
              className={`${styles.svgShape}`}
              overflow='visible'
              ref={pathRef}
              x={svgPathX}
              y='-3px'
              width='100%'
              // height="105%"
              viewBox='0 0 532 902'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                id='Tear'
                d='M527.277 1C527.277 1 538.234 89.4013 521.015 113.853C502.231 148.649 500.666 151.47 492.839 163.696C478.751 183.445 475.62 206.956 463.098 219.182C450.575 231.407 411.441 259.621 391.092 276.549C366.046 300.06 402.049 315.107 361.35 328.273C314.39 341.439 254.907 389.401 245.515 411.031C231.426 437.364 220.469 464.636 214.208 491.909C207.946 529.527 206.381 564.323 176.639 613.226C162.551 633.915 145.333 653.665 126.548 672.473C110.895 689.401 106.199 684.699 92.1108 701.627C76.4574 722.317 68.6306 743.947 67.0653 766.517C67.0653 792.849 65.4999 795.671 74.892 816.361C81.1534 828.586 101.503 838.931 110.895 852.097C120.287 865.263 186.031 869.965 198.554 879.37C203.25 883.132 209.512 885.953 214.208 889.715C218.904 894.417 239.253 901 239.253 901H10.7129C10.7129 901 23.2356 888.774 20.1049 867.144C16.9743 857.74 16.9743 848.335 20.1049 839.872C23.2356 820.122 15.4089 818.241 29.497 801.313C42.0198 790.028 37.3238 781.564 34.1931 764.636C29.497 742.066 21.6703 749.589 20.1049 731.721C16.9743 718.555 2.88616 704.448 37.3237 684.699C54.5425 672.473 74.892 661.188 96.8068 650.843C135.94 632.975 137.506 630.154 159.421 608.523C179.77 577.489 173.509 569.966 182.901 542.693C187.597 517.301 186.032 490.969 179.77 465.577C176.639 444.887 162.551 439.245 145.333 416.674C135.94 390.342 159.421 377.176 176.639 361.188C200.12 340.498 225.165 321.69 251.776 302.881C273.691 282.191 290.91 269.966 300.302 251.157C309.694 232.348 298.736 219.182 298.736 199.433C298.736 192.85 308.128 178.743 334.739 158.994C356.654 143.947 366.046 131.721 378.569 125.138C394.222 116.674 409.876 102.567 409.876 95.9843C413.006 88.4608 413.006 79.9969 409.876 72.4734C403.614 62.1285 395.523 52.9044 383 43.5C370.477 34.0956 366.023 28.6731 353.5 19C331.585 2.0721 314.39 1 314.39 1H527.277Z'
                fill='black'
              />
            </svg>

            {/* <svg
              ref={pathRef}
              viewBox="0 0 56 101"
              x="350px"
              y="-3px"
              height="101%"
              fillRule="evenodd"
              clipRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
            >
              <g
                id="FilterPath"
                transform="matrix(.94658 0 0 .9489 -911.204 -360.231)"
              >
                <path
                  fill="none"
                  d="M962.625 379.627h59.139v105.846h-59.139z"
                />
                <clipPath id="_clip1">
                  <path d="M962.625 379.627h59.139v105.846h-59.139z" />
                </clipPath>
                <g clipPath="url(#_clip1)">
                  <path
                    d="M1541.9 975.497s-4.3 4.303-4.8 11.903c-.2 3.3 2.1-.8 2.3 5 .2 5.7-1.8 6.4-4.2 7.8-2.4 1.4-1.1 3.8-.9 5.5.3 1.7-.3 3.4-1.8 6.1-1.3 2.4-6.3 6.7-8.1 9.1-1.8 2.4-8 4.7-9.4 9.1-1.3 4.2.3 12.1-.5 13.7-.7 1.4-1.9 4.5-2.6 6.7-.7 2.2-3 1.3-4.2 3.3-1.2 2 .5 5.4.6 7.3.1 3-1.5 3.2-.5 5.6.3.8 1.2 2.5 3.1 4 2 1.6 2.7 2 4.9 3.1 1.2.5 1.6.8 3.5 1.2 2.8.6 5.3.88 5.3.88H1495s2-.48 3-2.08c1.6-2.4 2-3.7 2.3-5.2.5-2.2 2.4-2.7 2.6-9.3.1-4.4-3.6-3-3.5-5.3 0-1.1 1.2-3.6.8-5.6-.3-1.7-.8-3.7-1.4-4.9-.6-1.2.5-2.9-.9-4-1.6-1.2-1.2-.9-1.4-1.8-.1-.7-.1-2.3.9-5.3.2-.7 1.5-4.1 2.4-6.1.8-1.9 1.3-2.6 1.9-4.2.7-1.6.6-3.9 1.3-5.5.5-1.2 5-5.9 6.6-8.3 1.7-2.3-3.7-5.6-1.6-7.7 2.1-2.2-.2-3 .2-3.7.4-.7 1-1.9 3-4.3 2.1-2.3 2.9-2.9 3.8-4 .9-1.1 1.9-2.3 1.9-3.1-.1-.8.1-1.2-1-2.5s-3.8-3.3-4.8-3.8-4.3-1.8-6.2-2.1c-1.9-.4-7.8-1.503-7-1.503 1.6.1 44 0 44 0Z"
                    transform="matrix(1.05643 0 0 1.05384 -616.433 -648.36)"
                    id="Tear1"
                    style={{ fill: "rgb(0,0,0)" }}
                  />
                </g>
              </g>
            </svg> */}
          </mask>
        </defs>
        <rect
          mask='url(#mask)'
          x='0'
          y='0'
          width='100%'
          height='100%'
          fill='#e4d792'
        />
      </svg>
    </div>
  );
});

const ShaderCanvas = () => {
  return (
    <Canvas className={styles.canvas} camera={{ position: [0, 0, 1], fov: 60 }}>
      <Suspense fallback={null}>
        <GlslPlane />
      </Suspense>
    </Canvas>
  );
};

export { ShaderCanvas, SVGFilter };
