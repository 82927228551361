// extracted by mini-css-extract-plugin
export var ourClientsWrapper = "index-module--our-clients-wrapper--y+8m+";
export var mobile = "index-module--mobile--nYPPo";
export var topContainer = "index-module--top-container--+vEKI";
export var featureText = "index-module--featureText--ZcN4s";
export var smWrapper = "index-module--sm-wrapper--Yucjw";
export var testimonyCarousel = "index-module--testimony-carousel--UH2FM";
export var carouselPagination = "index-module--carousel-pagination--iTIAY";
export var paginationItem = "index-module--pagination-item--l6WGy";
export var testimonyFilter = "index-module--testimony-filter--wMM0i";
export var dropdownWrapper = "index-module--dropdown-wrapper--SAfeg";
export var swiperWrapper = "index-module--swiper-wrapper--Tfz1P";
export var swiperSlide = "index-module--swiper-slide--7dDUP";
export var testimonyBlockWrapper = "index-module--testimony-block-wrapper--Ecggk";
export var active = "index-module--active--2GSDm";
export var testimonyBlock = "index-module--testimony-block--TyC9o";
export var logoWrapper = "index-module--logo-wrapper--PZddR";
export var bottomContainer = "index-module--bottom-container--XAyxh";
export var filterList = "index-module--filter-list--QIC6x";
export var filterOption = "index-module--filter-option--mda7S";
export var clientList = "index-module--client-list--H5U3J";
export var client = "index-module--client--sEuWU";