import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback,
} from 'react';
import * as styles from './index.module.scss';
import { Transition } from 'react-transition-group';
import { setOnFooterSection } from '../../../state/nav';
import { useDispatch, useSelector } from 'react-redux';
import { addTransition, removeTransition } from '../../../state/nav';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';
import Footer from '../../footer';
import { debounce } from 'lodash';
import useSwipe from '../../../hooks/useSwipe';
import useWheel from '../../../hooks/useWheel';
import gsap, { Cubic, Sine } from 'gsap';
const FooterSectionTransition = (props) => {
  const animDuration = 1;
  const { show, sectionParallaxDirection } = props;
  const activeTransitions = useSelector((state) => state.nav.activeTransitions);
  const footerSectionRef = useRef();
  const dispatch = useDispatch();
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) => {
        sectionEnter(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('footer'));
            gsap.from(footerSectionRef.current, {
              transformOrigin: 'center center',
              backgroundPositionY: '180px',
              ease: Sine.easeInOut,
              duration: animDuration,
            });
          },
          onComplete: () => {
            dispatch(removeTransition('footer'));
          },
        });
      }}
      onExit={(node) =>
        sectionExit(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            // footer specific animation
            dispatch(removeTransition('footer'));
            gsap.to(footerSectionRef.current, {
              transformOrigin: 'center center',
              backgroundPositionY: '-500px',
              ease: Cubic.easeIn,
              duration: animDuration,
            });
          },
          onComplete: () => {},
        })
      }
    >
      <FooterSection
        ref={footerSectionRef}
        animDuration={animDuration}
        canTransition={activeTransitions.length === 0}
        {...props}
      />
    </Transition>
  );
};

const FooterSection = forwardRef(
  ({ goToPreviousSection, canTransition, animDuration }, ref) => {
    const dispatch = useDispatch();
    const localTransitionLocked = useRef(true);

    const handleWheel = (event) => {
      if (!canTransition || localTransitionLocked.current) {
        return;
      }
      if (event.deltaY > 0) {
        // scrolls down
      } else {
        // scrolls up
        localTransitionLocked.current = true;
        goToPreviousSection();
      }
    };

    const [onWheel] = useWheel(handleWheel, {
      threshold: 10,
      throttle: 1000,
      canTriggerCallback: () => canTransition && !localTransitionLocked.current,
    });

    const handleSwipe = (direction) => {
      if (!canTransition || localTransitionLocked.current) {
        return;
      }
      if (direction === 'up') {
      } else if (direction === 'down') {
        localTransitionLocked.current = true;
        goToPreviousSection();
      }
    };

    const [touchStart, touchEnd] = useSwipe(handleSwipe, {
      threshold: 50,
      throttle: 1000,
      canTriggerCallback: () => canTransition && !localTransitionLocked.current,
    });

    useEffect(() => {
      dispatch(setOnFooterSection(true));
      const transitionLocked = setTimeout(() => {
        localTransitionLocked.current = false;
      }, animDuration * 1000 + 50);
      return () => {
        clearTimeout(transitionLocked);
        dispatch(setOnFooterSection(false));
      };
    }, []);

    return (
      <div
        ref={ref}
        className={styles.footerSectionWrapper}
        onWheel={onWheel}
        onTouchStart={touchStart}
        onTouchEnd={touchEnd}
      >
        <Footer />
      </div>
    );
  }
);

export default FooterSectionTransition;
