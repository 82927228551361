import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import Slogan from '../slogan';

const SloganMantra = ({ hideDivider }, ref) => {
  return (
    <div ref={ref} className={styles.mantraWrapper}>
      <div className={`${styles.sloganWrapper} ${hideDivider && styles.hideDivider}`}>
        <Slogan width={120} spin={true} />
      </div>  
     <div className={styles.separator} />
      <p className={`body-S ${styles.mantra}`}>
        We work with a handful of restless leadership teams to help build
        businesses, brands, products and experiences for the customers of
        tomorrow.
      </p>
    </div>
  );
};

export default forwardRef(SloganMantra);
