// extracted by mini-css-extract-plugin
export var container = "index-module--container--J02ig";
export var canvasWrapper = "index-module--canvasWrapper--p5QFE";
export var fallbackBackground = "index-module--fallback-background--qnxFh";
export var tabletTear = "index-module--tablet-tear--QnQYY";
export var maskContainer = "index-module--mask-container--Cwoc3";
export var heroSection = "index-module--heroSection--N9832";
export var heroBlockWrapper = "index-module--heroBlockWrapper--Qi9Wh";
export var heroBlockOuter = "index-module--hero-block-outer--Sapu+";
export var sloganWrapper = "index-module--slogan-wrapper--KmA+5";
export var shapeCanvasWrapper = "index-module--shapeCanvasWrapper--uc2hb";
export var carouselWrapper = "index-module--carousel-wrapper--BSByX";
export var pagination = "index-module--pagination--BpGAE";
export var paginationItem = "index-module--pagination-item--tGEhL";
export var active = "index-module--active--ErmSW";
export var slides = "index-module--slides--abc9V";
export var tearBackground = "index-module--tear-background--wWew6";
export var heroSlide = "index-module--hero-slide--yl9eB";
export var heroShapeWrapper = "index-module--hero-shape-wrapper--n3RTu";
export var heroShape = "index-module--hero-shape--IRCVD";
export var textWrapper = "index-module--text-wrapper--ZJqJr";
export var title = "index-module--title--OiI79";
export var description = "index-module--description--Ogdsy";