import React, { useEffect, useState, useRef } from 'react';
import * as styles from './index.module.scss';
// import chevron from '';
import { StaticImage } from 'gatsby-plugin-image';
import useStateRef from '../../hooks/useStateRef';
import { Transition } from 'react-transition-group';
import gsap, { Cubic } from 'gsap';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import chevronDown from '../../assets/images/chevron-down.svg';
const Dropdown = ({ options, onChange, autoHide, fullWidth }) => {
  const [value, setValue] = useState(options[0]);
  const dropdownRef = useRef();
  const [isOpen, setisOpen, isOpenRef] = useStateRef(false);

  const clickEvent = (e) => {
    if (!dropdownRef.current.contains(e.target) && isOpenRef.current === true) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickEvent);
    return () => {
      document.removeEventListener('click', clickEvent);
    };
  }, []);

  const toggleMenu = () => {
    setisOpen(!isOpen);
  };

  const closeMenu = () => {
    setisOpen(false);
  };

  const selectOption = (option) => {
    setValue(option);
    onChange(option);
    closeMenu();
  };
  return (
    <div
      ref={dropdownRef}
      className={`${styles.dropdownWrapper} ${fullWidth && styles.fullWidth}`}
    >
      <div onClick={toggleMenu} className={styles.dropdown}>
        <span className={`utility-button ${styles.dropdownText}`}>{value}</span>{' '}
        <img alt="an icon of a chevron pointing downwards" className={styles.chevron} src={chevronDown} />
      </div>
      <Transition
        timeout={150}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node) => {
          gsap.from(node, {
            transformOrigin: '0% 0%',
            duration: 0.15,
            opacity: 0,
            scaleY: 0,
            ease: Cubic.easeInOut,
          });
        }}
        onExit={(node) => {
          gsap.to(node, {
            transformOrigin: '0% 0%',
            duration: 0.15,
            opacity: 0,
            scaleY: 0,
            ease: Cubic.easeInOut,
          });
        }}
      >
        <div className={styles.optionsContainer}>
          <SimpleBar
            onWheel={(e) => {
              e.stopPropagation();
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
            autoHide={autoHide !== null ? autoHide : true}
            // scrollableNodeProps={{ ref: barRef }}
            style={{ maxHeight: '200px', minWidth: '100%', width: '250px' }}
          >
            {options.sort().map((option) => (
              <div
                key={option}
                onClick={() => {
                  selectOption(option);
                }}
                className={`body-S ${styles.option} ${
                  option === value ? styles.active : null
                }`}
              >
                {option}
              </div>
            ))}
          </SimpleBar>
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
