import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import Slogan from '../../../slogan';
import Squiggly from '../../../squiggly';
import ButtonLink from '../../../button/button-link';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import CaseStudyShowcase from '../../../case-study-showcase';
import {
  sectionEnter,
  sectionExit,
} from '../../../animate/landing-section-transitions';

import { isMobile, isTablet } from 'react-device-detect';
const CaseStudyPreviewTransition = (props) => {
  const {
    show,
    scrollDirection,
    onExitStart,
    onExitComplete,
    caseStudy,
    fixed,
  } = props;

  const animDuration = 1;
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) =>
        sectionEnter(node, animDuration, {
          sectionParallaxDirection: scrollDirection,
        })
      }
      onExit={(node) => {
        onExitStart();
        sectionExit(node, animDuration, {
          sectionParallaxDirection: scrollDirection,
          onComplete: onExitComplete,
        });
      }}
    >
      <CaseStudyShowcase
        fixed={fixed}
        category={
          caseStudy.attributes.case_study_category.data.attributes.title
        }
        description={caseStudy.attributes.description}
        image={caseStudy.attributes.featureImage.data.attributes.url}
        roadmap={caseStudy.attributes.roadmap}
        slug={caseStudy.attributes.slug}
        title={caseStudy.attributes.title}
        weDid={caseStudy.attributes.case_study_works.data
          .map((csw) => csw.attributes.title)
          .join(', ')}
        website={caseStudy.attributes.website}
      />
    </Transition>
  );
};

export default CaseStudyPreviewTransition;
