import React from 'react';
import * as styles from './index.module.scss';
import { Transition } from 'react-transition-group';
import gsap, { Quint, Back, Linear, Power1 } from 'gsap';
// import { StaticImage } from 'gatsby-plugin-image';

const HeroShape = ({ imageUrl, in: show, type }) => {
  const animDuration = type === 'slow' ? 1 : 1.35;
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) => {
        gsap.from(node, {
          transformOrigin: '50% 50%',
          duration: animDuration,
          opacity: 0,
          x: -100,
          clearProps: 'transformOrigin',
          ease: type === 'slow' ? Power1.easeInOut : Quint.easeIn,
        });
      }}
      onExit={(node) => {
        gsap.to(node, {
          transformOrigin: '50% 50%',
          duration: animDuration,
          opacity: 0,
          x: 100,
          clearProps: 'transformOrigin',
          ease: type === 'slow' ? Power1.easeInOut : Quint.easeOut,
        });
      }}
    >
      <img className={styles.shape} src={imageUrl} alt='3d hero shape' />
    </Transition>
  );
};

export default HeroShape;
