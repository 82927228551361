import React, { useState, useRef, useEffect, useCallback } from 'react';
const useSwipe = (
  onSwipeCallback,
  { threshold, throttle, canTriggerCallback, horizontal }
) => {
  const savedCallback = useRef(onSwipeCallback);
  const [touch, setTouch] = useState(0);
  const currentlyThrottled = useRef(false);
  let throttleTimeout = null;

  // 1100 drop 4 pixels

  useEffect(() => {
    savedCallback.current = onSwipeCallback;
  }, [onSwipeCallback]);

  const touchStart = (e) => {
    setTouch({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };
  const touchEnd = (e) => {
    let distance = 0;
    if (horizontal) {
      distance = Math.abs(touch.x - e.changedTouches[0].clientX);
    } else {
      distance = Math.abs(touch.y - e.changedTouches[0].clientY);
    }

    if (distance > threshold) {
      // check if throttled, if not run touch input callback and start throttle
      if (!currentlyThrottled.current && canTriggerCallback()) {
        if (horizontal) {
          if (touch.x > e.changedTouches[0].clientX) {
            savedCallback.current('left');
          } else {
            savedCallback.current('right');
          }
        } else {
          if (touch.y > e.changedTouches[0].clientY) {
            savedCallback.current('up');
          } else {
            savedCallback.current('down');
          }
        }

        // only if throttled
        if (throttle) {
          currentlyThrottled.current = true;
          throttleTimeout = setTimeout(() => {
            currentlyThrottled.current = false;
          }, throttle); // if none is given
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      if (throttleTimeout !== null) {
        clearTimeout(throttleTimeout);
      }
    };
  }, []);

  // expose event handler callbacks
  return [touchStart, touchEnd];
};
export default useSwipe;
