import CubeImage from '../assets/images/shapes/cube.png';
import TorusImage from '../assets/images/shapes/torus.png';
import DollosImage from '../assets/images/shapes/dollos.png';
import SphereImage from '../assets/images/shapes/sphere.png';
import ConeImage from '../assets/images/shapes/cone.png';

export const heroSlides = [
  {
    name: 'cube',
    imageUrl: CubeImage,
    title: 'Hello curious visitor. We are Lucky Beard.',
    subtitle: 'We make things that change things. Quickly.',
    fill: '#E4D792',
    path: 'M527.277 1C527.277 1 538.234 89.4013 521.015 113.853C502.231 148.649 500.666 151.47 492.839 163.696C478.751 183.445 475.62 206.956 463.098 219.182C450.575 231.407 411.441 259.621 391.092 276.549C366.046 300.06 402.049 315.107 361.35 328.273C314.39 341.439 254.907 389.401 245.515 411.031C231.426 437.364 220.469 464.636 214.208 491.909C207.946 529.527 206.381 564.323 176.639 613.226C162.551 633.915 145.333 653.665 126.548 672.473C110.895 689.401 106.199 684.699 92.1108 701.627C76.4574 722.317 68.6306 743.947 67.0653 766.517C67.0653 792.849 65.4999 795.671 74.892 816.361C81.1534 828.586 101.503 838.931 110.895 852.097C120.287 865.263 186.031 869.965 198.554 879.37C203.25 883.132 209.512 885.953 214.208 889.715C218.904 894.417 239.253 901 239.253 901H10.7129C10.7129 901 23.2356 888.774 20.1049 867.144C16.9743 857.74 16.9743 848.335 20.1049 839.872C23.2356 820.122 15.4089 818.241 29.497 801.313C42.0198 790.028 37.3238 781.564 34.1931 764.636C29.497 742.066 21.6703 749.589 20.1049 731.721C16.9743 718.555 2.88616 704.448 37.3237 684.699C54.5425 672.473 74.892 661.188 96.8068 650.843C135.94 632.975 137.506 630.154 159.421 608.523C179.77 577.489 173.509 569.966 182.901 542.693C187.597 517.301 186.032 490.969 179.77 465.577C176.639 444.887 162.551 439.245 145.333 416.674C135.94 390.342 159.421 377.176 176.639 361.188C200.12 340.498 225.165 321.69 251.776 302.881C273.691 282.191 290.91 269.966 300.302 251.157C309.694 232.348 298.736 219.182 298.736 199.433C298.736 192.85 308.128 178.743 334.739 158.994C356.654 143.947 366.046 131.721 378.569 125.138C394.222 116.674 409.876 102.567 409.876 95.9843C413.006 88.4608 413.006 79.9969 409.876 72.4734C403.614 62.1285 395.523 52.9044 383 43.5C370.477 34.0956 366.023 28.6731 353.5 19C331.585 2.0721 314.39 1 314.39 1H527.277Z',
    x: 0,
  },
  {
    name: 'cone',
    imageUrl: ConeImage,
    title: 'We are a global design and advisory firm.',
    subtitle:
      'We work with restless leadership teams to help build businesses, brands, products and experiences for the customers of tomorrow.',
    fill: '#E1BBAB',
    path: 'M527.208 1.00003C527.208 1.00003 460.622 6.26931 419 48C390.306 76.7693 386.214 82.7328 368.105 134.403C346.115 185.134 347.409 194.528 348.702 225.53C351.289 253.714 357.757 259.351 359.051 274.382C365.518 292.232 370.692 310.081 373.279 328.871C374.573 354.236 374.573 388.056 352.583 409.664C330.593 431.271 329.3 448.182 337.061 492.336C349.996 543.067 371.986 597.555 362.931 611.647C352.583 626.679 343.528 641.71 335.767 657.681C328.006 673.651 321.538 690.562 320.245 708.411C317.658 726.261 312.484 743.171 303.429 760.081C289.2 786.386 300.842 802.357 259.449 798.599C227.111 792.023 236.166 814.57 249.101 826.783C269.798 846.511 255.569 836.177 278.852 850.269C296.962 861.543 316.364 870.937 337.061 878.453C349.996 884.09 364.225 897.242 378.453 901H7.21289C7.21289 901 14.974 890.666 27.9092 876.574C47.312 854.967 24.0287 844.633 58.9537 830.541C82.2371 817.388 108.108 808.933 110.695 750.687C111.988 711.23 110.695 728.14 111.988 708.411C111.988 699.017 117.162 669.894 111.988 652.983C108.107 637.952 96.4658 632.315 88.7047 621.981C80.9436 611.647 57.6602 603.192 65.4213 582.524C74.476 562.795 84.8241 543.067 86.1176 528.036C88.7047 515.823 84.8241 519.58 91.2917 488.578C93.8788 482.002 91.2917 472.608 96.4658 449.121C100.346 435.029 100.346 419.998 96.4658 405.906C84.8241 387.117 55.0731 382.42 64.1278 368.328C70.5954 357.994 128.804 317.597 148.207 296.929C168.903 277.2 179.251 252.775 177.958 228.349C172.784 206.741 175.371 202.044 180.545 195.468C190.893 181.376 198.771 176.213 213 164C238.87 143.332 235.5 146.5 251 137C264 127 290 112.5 290 95C290 77.5 283.261 73.0762 275.5 66.5C258.684 53.3476 237.46 47.0334 216.763 37.6389C192.186 29.1837 167.609 23.547 140.446 18.8497C117.162 15.0919 52.4861 1.00003 52.4861 1.00003H527.208Z',
    x: 0,
  },
  {
    name: 'torus',
    imageUrl: TorusImage,
    title: 'If it doesn’t exist, we build it.',
    subtitle:
      'We build products and businesses from scratch based on an industry gap or current inefficiency. Keep in mind, the businesses that will thrive in the world of tomorrow might not yet exist.',
    fill: '#B0D4CE',
    path: 'M238.15 1C238.15 1 127 31 89.0933 72.4734C66.0433 95.9843 72.19 133.602 79.8733 155.232C95.24 189.088 116.753 201.313 135.193 221.063C153.633 237.05 172.073 253.038 187.44 269.966C205.88 286.893 215.1 306.643 215.1 326.392C216.637 349.903 224.32 381.878 222.784 409.15C221.247 436.423 228.93 463.696 242.76 489.088C264.274 543.633 353.4 567.144 370.304 587.834C381.06 601 385.67 625.451 408.721 645.201C427.161 661.188 436.381 667.771 453.284 683.759C470.187 699.746 480.944 711.031 480.944 727.959C480.944 754.292 488.627 751.47 505.531 767.458C522.434 779.683 531.654 794.73 530.117 809.777C530.117 833.288 530.117 834.229 528.581 849.276C527.044 865.263 517.824 865.263 514.751 877.489C514.751 883.132 516.287 896.298 520.897 901H305.764C330.35 894.417 351.864 876.549 370.304 865.263C391.817 852.097 405.647 835.169 408.721 816.361C411.794 796.611 391.817 781.564 354.937 760.875C325.74 743.947 298.08 727.019 299.617 706.329C299.617 696.925 301.154 690.342 299.617 672.473C298.08 657.426 314.984 637.677 282.714 622.63C268.884 612.285 245.834 597.238 227.394 585.013C213.564 575.608 202.807 566.204 190.514 555.859C181.293 547.395 172.073 537.05 165.927 527.646C150.56 508.837 150.56 486.266 165.927 467.458C175.147 453.351 179.757 438.304 179.757 423.257C182.83 402.567 182.83 382.818 179.757 362.129C178.22 341.439 170.537 320.749 158.243 301.94C147.487 285.013 129.047 270.906 104.46 260.561C86.02 248.335 66.0433 243.633 47.6033 232.348C39.9199 227.646 30.6999 228.586 16.8699 211.658C6.1132 189.088 10.7232 186.266 15.3332 175.922C19.9432 165.577 33.7733 154.661 36.8466 146.768C39.9199 138.875 47.6033 122.5 39.9199 105.389C32.2366 88.2774 26.0899 65.8903 19.9432 62.1285C13.7965 57.4263 23.0166 38.6176 24.5532 25.4514C29.1632 9.46395 23.0166 1 23.0166 1H238.15Z',
    x: 0,
  },
  {
    name: 'sphere',
    imageUrl: SphereImage,
    title: 'If it’s behind, we transform it.',
    subtitle: 'Innovating from within can be difficult to achieve. That’s why we step in to help businesses modernise themselves through transformation and growth.',
    fill: '#DAA098',
    path: 'M325.584 1C325.584 1 169.666 48.9624 156.054 114.793C152.342 128.9 151.105 142.066 151.105 156.172C153.579 206.956 169.666 214.48 180.803 225.765C203.077 246.455 221.639 260.561 232.776 276.549C241.438 293.476 252.575 310.404 263.712 326.392C282.274 352.724 295.886 380.937 302.073 410.091C311.972 439.244 329.297 467.458 354.046 490.969C387.457 522.003 402.306 533.288 418.393 549.276C434.479 565.263 465.416 590.655 465.416 611.345C466.653 631.094 472.84 649.903 486.452 666.831C498.827 685.64 495.114 706.329 496.352 722.317C497.589 748.649 495.114 761.815 506.251 782.505C509.964 790.028 516.151 804.135 522.338 819.182C528.525 833.288 527.288 848.335 518.626 861.502C513.676 869.025 511.201 876.549 508.726 885.013C507.489 887.834 507.489 899.119 507.489 901H65.7208L158.529 877.489C225.351 868.085 257.525 843.633 266.187 829.527C269.899 821.063 294.648 784.386 241.438 758.053C180.803 727.959 168.429 704.448 164.716 696.925C156.054 684.699 174.616 662.129 174.616 651.784C174.616 638.618 177.091 629.213 169.666 618.868C162.242 608.524 159.767 593.476 147.392 576.549C137.493 563.382 131.305 550.216 122.643 540.812C117.694 532.348 111.506 525.765 94.182 500.373C83.045 480.624 63.2459 460.875 53.3463 443.006C40.9719 417.614 27.36 405.389 22.4102 395.984C5.08594 373.414 3.8485 361.188 12.5106 347.082C18.6978 336.737 54.5838 320.749 58.2961 309.464C68.1957 284.072 76.8578 252.097 80.5701 241.752C84.2825 227.646 86.7573 212.599 86.7573 198.492C87.9948 189.088 86.7573 175.922 89.2322 167.458C91.7071 148.649 92.9446 143.947 94.182 130.781C94.182 123.257 94.182 114.793 94.182 107.27C92.9446 97.8652 91.7071 89.4013 87.9948 80.9373C84.2824 70.5925 79.3327 60.2477 73.1454 49.9028C66.9582 40.4984 58.2961 32.9749 47.1591 26.3918C34.7846 18.8683 6.20508 1 6.20508 1H325.584Z',
    x: 0,
  },
  {
    name: 'Dollos',
    imageUrl: DollosImage,
    title: 'If it’s inefficient, we optimise it.',
    subtitle: 'We future proof already successful businesses by unlocking their untapped potential, growth opportunities and completely new verticals.',
    fill: '#88A392',
    path: 'M352.484 1C352.484 1 237.36 33.9498 233.479 74.431C230.892 103.615 270.992 123.385 287.808 154.452C298.156 177.046 298.156 193.05 304.624 216.586C308.504 234.473 314.972 251.418 325.32 268.364C335.669 283.427 366.713 307.904 348.604 331.439C333.081 353.092 327.907 373.803 316.266 404.87C302.037 443.469 303.33 459.473 330.494 496.188C352.484 518.782 362.833 546.084 358.952 573.385C355.072 602.569 347.31 631.753 335.669 659.996C326.614 679.766 318.853 702.36 330.494 724.954C340.843 744.724 342.136 753.197 352.484 769.201C362.833 785.205 357.659 799.326 364.126 824.745C368.007 836.042 375.768 846.398 384.823 855.812C397.758 867.109 414.574 875.582 433.977 879.347C446.912 883.113 461.141 885.937 474.076 886.879C507.708 892.527 527.111 901 527.111 901H57.5591C57.5591 901 139.052 884.996 177.858 875.582C214.076 866.167 259.35 841.69 263.231 828.511C267.111 808.741 270.992 789.912 208.902 753.197C189.499 743.782 180.445 727.778 166.216 717.423C151.987 707.067 153.28 684.473 159.748 666.586C168.803 652.465 157.161 631.753 149.4 621.398C141.639 611.042 105.42 584.682 88.6039 575.268C71.7879 563.971 58.8526 549.849 53.6785 534.787C52.385 528.197 48.5044 524.431 61.4397 497.13C65.3203 487.716 70.4944 478.301 76.9621 468.887C84.7233 455.707 104.126 444.41 102.833 424.64C100.246 401.105 92.4845 397.339 88.6039 385.1C82.1362 367.213 61.4397 353.092 61.4397 324.849C61.4397 296.607 71.7879 275.895 89.8974 262.715C108.007 248.594 118.355 231.649 122.236 212.82C124.823 193.992 122.236 176.105 114.475 157.276C110.594 145.979 105.42 134.682 97.6586 124.326C92.4845 113.971 86.0168 104.556 75.6685 96.0837C69.2009 89.4937 62.7332 88.5523 48.5044 77.2552C39.4496 70.6653 32.982 62.1925 29.1014 52.7782C23.9273 43.364 26.5143 36.7741 20.0467 27.3598C17.4596 20.7699 7.11133 1 7.11133 1H352.484Z',
    x: 0,
  },
];