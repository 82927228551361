import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import Slogan from '../../slogan';
import ArrowRight from '../../arrow-right';
import GradientText from '../../gradient-text';
import gsap, { Cubic } from 'gsap';
import { Transition } from 'react-transition-group';
import { setOnWhatWeDoSection } from '../../../state/nav';
import { useDispatch, useSelector } from 'react-redux';
import { addTransition, removeTransition } from '../../../state/nav';
import useWheel from '../../../hooks/useWheel';
import useSwipe from '../../../hooks/useSwipe';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';

const WhatWeDoSectionTransition = (props) => {
  const animDuration = 1;
  const { show, sectionParallaxDirection } = props;
  const activeTransitions = useSelector((state) => state.nav.activeTransitions);
  const dispatch = useDispatch();
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) =>
        sectionEnter(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('what-we-do'));
          },
          onComplete: () => {
            dispatch(removeTransition('what-we-do'));
          },
        })
      }
      onExit={(node) =>
        sectionExit(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('what-we-do'));
          },
          onComplete: () => {
            dispatch(removeTransition('what-we-do'));
          },
        })
      }
    >
      <WhatWeDoSection
        animDuration={animDuration}
        canTransition={activeTransitions.length === 0}
        {...props}
      />
    </Transition>
  );
};

// import
const WhatWeDoSection = ({
  goToPreviousSection,
  goToNextSection,
  animDuration,
  canTransition,
}) => {
  const [wwdList] = useState([
    {
      title: 'Business design',
      text: 'We help your business discover new opportunities for growth',
      link: '',
    },
    {
      title: 'Brand design',
      text: 'We help you build brands people can believe in',
      link: '',
    },
    {
      title: 'Product design',
      text: 'We help you build products people will fall in love with ',
      link: '',
    },
    {
      title: 'Experience design',
      text: 'We help you create joyful, delightful experiences',
      link: '',
    },
  ]);
  const localTransitionLocked = useRef(true);
  const dispatch = useDispatch();
  const handleWheel = (event) => {
    if (!canTransition || localTransitionLocked.current) {
      return;
    }

    localTransitionLocked.current = true;
    if (event.deltaY > 0) {
      // scrolls down
      goToNextSection();
    } else {
      // scrolls up
      goToPreviousSection();
    }
  };

  // const throttledScroll = useThrottle(handleScroll, 1300);
  const [onWheel] = useWheel(handleWheel, {
    threshold: 10,
    throttle: 1000,
    canTriggerCallback: () => canTransition && !localTransitionLocked.current,
  });

  const handleSwipe = (direction) => {
    if (!canTransition || localTransitionLocked.current) {
      return;
    }

    if (direction === 'up') {
      goToNextSection();
    } else {
      goToPreviousSection();
    }
  };

  const [touchStart, touchEnd] = useSwipe(handleSwipe, {
    threshold: 50,
    throttle: 1000,
    canTriggerCallback: () => canTransition && !localTransitionLocked.current,
  });

  useEffect(() => {
    dispatch(setOnWhatWeDoSection(true));
    // barElRef.current.addEventListener('wheel', handleScroll);
    // initScrollTrigger(); // handles scroll trigger trigger points and animation positions
    const transitionLocked = setTimeout(() => {
      localTransitionLocked.current = false;
    }, animDuration * 1000 + 50);
    return () => {
      clearTimeout(transitionLocked);
      dispatch(setOnWhatWeDoSection(false));
    };
  }, []);
  const breakpoints = useBreakpoint();
  const weWorkText =
    'We work with restless leadership teams to help build businesses, brands, products and experiences for the customers of tomorrow';
  return (
    <div
      onWheel={onWheel}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      className={styles.whatWeDoWrapper}
    >
      <div className={styles.innerLeft}>
        <div className={styles.sloganWrapper}>
          <Slogan width={breakpoints.md && 135} spin={true} />
        </div>
        <GradientText text={weWorkText} customClass={styles.customText} />
      </div>
      <div className={styles.innerRight}>
        {wwdList.map((item, i) => (
          <div key={`wwd-${i}`} className={`${styles.wwdType}`}>
            <h3 className={'title-XS bold'}>{item.title}</h3>
            <p className={'body-M'}>{item.text} </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeDoSectionTransition;
