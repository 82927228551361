import gsap, { Cubic } from 'gsap';

export const sectionEnter = (node, duration, options) => {
  const dir = options.sectionParallaxDirection === 'up' ? 1 : -1;
  gsap.set(node, {
    zIndex: 2,
  });
  gsap.from(node, {
    duration: duration,
    y: window.innerHeight * dir,
    ease: Cubic.easeInOut,
    onStart: options.onStart,
    onComplete: options.onComplete,
  });
};

export const sectionExit = (node, duration, options) => {
  const dir = options.sectionParallaxDirection === 'up' ? -1 : 1;
  const colours = ['#E1BBAB','#E4D792', '#B0D4CE', '#DAA098', '#88A392'];
  gsap.set(node, {
    zIndex: 1,
  });
  gsap.to(node, {
    // transformOrigin: `50% ${dir === 1 ? 100 : 0}%`,
    // background: colours[Math.floor(Math.random() * colours.length)],
    duration: duration,
    opacity:0,
    y: (window.innerHeight * dir) / 3,
    ease: Cubic.easeInOut,
    onStart: options.onStart,
    onComplete: options.onComplete,
  });

  // cool case study animation
  // gsap
  //   .timeline({})
  //   .add(
  //     gsap.to(node, {
  //       background: '#E1BBAB',
  //       duration: 0.3,
  //       transformOrigin: '50% 50%',
  //       scale: 0.9,
  //     })
  //   )
  //   .add(
  //     gsap.to(node, {
  //       duration: 0.3,
  //       scale: 1,
  //       background: '#F9F9FA'
  //     }),
  //     0.7
  //   );
};
