import React, { useRef, useState, useEffect, useCallback } from 'react';
import useWheel from '../../../hooks/useWheel';
import useSwipe from '../../../hooks/useSwipe';
// import * as threeStyles from '../styles/components/three.module.scss'
import * as styles from './index.module.scss';
import gsap, { Cubic } from 'gsap';
import { heroSlides } from '../../../constants/heroSlides';
import HeroShape from '../../hero-shape';
import HeroText from '../../hero-text';
import Slogan from '../../slogan';
import Throttle from 'lodash.throttle';
import { heroIn, heroScroll } from '../../animate';
import { Transition } from 'react-transition-group';
import { ShaderCanvas, SVGFilter } from './landing-shader';
import { useDispatch, useSelector } from 'react-redux';
import { addTransition, removeTransition } from '../../../state/nav';
import { setOnLandingSection } from '../../../state/nav';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  setLandingIndex,
  setAnimationType,
  setCanTransition,
} from '../../../state/home';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';
import { isTablet } from 'react-device-detect';

// isolates transitions from component logic
const LandingTransition = (props) => {
  const animDuration = 1;
  const { show, sectionParallaxDirection } = props;
  const filterRef = useRef(null);
  const dispatch = useDispatch();
  const landingIndex = useSelector((state) => state.home.landingIndex);
  const activeTransitions = useSelector((state) => state.nav.activeTransitions);

  const initLandingState = (i) => {
    // TODO: Add init animation state for background colours here on tablet and mobile view
    if (filterRef.current) {
      gsap.set(filterRef.current.lastChild, {
        fill: heroSlides[i].fill,
      });

      // // animate svg shape
      const pathElement =
        filterRef.current.firstChild.firstChild.lastChild.firstChild;
      gsap.set(pathElement, {
        x: heroSlides[i].x,
        attr: {
          d: heroSlides[i].path,
        },
      });
    }
  };

  // useEffect(() => {
  // }, [landingIndex]);

  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) => {
        initLandingState(landingIndex);
        sectionEnter(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('landing'));
          },
          onComplete: () => {
            dispatch(removeTransition('landing'));
          },
        });
      }}
      onExit={(node) =>
        sectionExit(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('landing'));
          },
          onComplete: () => {
            dispatch(removeTransition('landing'));
          },
        })
      }
    >
      <LandingSection
        animDuration={animDuration}
        canTransition={activeTransitions.length === 0}
        {...props}
        filterRef={filterRef}
      />
    </Transition>
  );
};

const LandingSection = ({
  goToNextSection,
  filterRef,
  canTransition,
  animDuration,
}) => {
  const landingIndex = useSelector((state) => state.home.landingIndex);
  const jumpSection = useSelector((state) => state.home.jumpSection);
  const animationType =
    useSelector((state) => state.home.animationType) || 'slow';
  const canTransitionLocal = useSelector((state) => state.home.canTransition);
  const sloganRef = useRef(null);
  const heroBlockOuterRef = useRef(null);
  const lastIndex = useRef(0);
  const landingRef = useRef(null);
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const localTransitionLocked = useRef(true);
  const breakpoints = useBreakpoint();
  const tearSize = 2500;
  const animationTime = 0.5;

  useEffect(() => {
    dispatch(setOnLandingSection(true));

    const transitionLocked = setTimeout(() => {
      localTransitionLocked.current = false;
    }, animDuration * 1000 + 50);
    return () => {
      clearTimeout(transitionLocked);
      dispatch(setOnLandingSection(false));
      dispatch(setLandingIndex(heroSlides.length - 1));
    };
  }, []);

  useEffect(() => {
    selectNewSection(landingIndex);
    lastIndex.current = landingIndex;
  }, [jumpSection]);

  // animates tablet fallback
  useEffect(() => {
    if (landingRef.current.querySelector('.fallback-bg')) {
      gsap.to(landingRef.current.querySelector('.fallback-bg'), {
        duration: animationTime,
        backgroundColor: heroSlides[landingIndex].fill,
      });
    }

    if (landingRef.current.querySelector('.tear-background')) {
      gsap.to(landingRef.current.querySelector('.tear-background'), {
        duration: animationTime * 2.25,
        backgroundPositionX:
          landingIndex * (window.innerWidth / heroSlides.length) * -1,
      });
    }
  }, [landingIndex]);

  const selectNewSection = (i) => {
    // prevent from firing on page land
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // skip, only if new screen allow animation
    if (landingIndex === lastIndex.current || !canTransitionLocal) {
      return;
    }
    dispatch(setCanTransition(false));

    if ((!breakpoints.md && !isTablet) === true) {
      heroIn(filterRef, heroBlockOuterRef, sloganRef, {
        fill: heroSlides[i].fill,
        path: heroSlides[i].path,
        x: heroSlides[i].x,
        onComplete: () => {
          dispatch(setCanTransition(true));
        },
      });
    }
  };

  const handleWheel = (event) => {
    if (
      !canTransitionLocal ||
      !canTransition ||
      localTransitionLocked.current
    ) {
      return;
    }

    if (event.deltaY > 0) {
      // scrolls down
      if (landingIndex + 1 < heroSlides.length) {
        // handle scroll down
        scrollToNewSection(landingIndex + 1);
      } else {
        if (canTransitionLocal) {
          // update
          goToNextSection();
          localTransitionLocked.current = true;
        }
      }
    } else {
      // scrolls up
      if (landingIndex - 1 >= 0) {
        // handle scroll up
        scrollToNewSection(landingIndex - 1);
      }
    }
  };

  const [onWheel] = useWheel(handleWheel, {
    threshold: 10,
    throttle: 1000,
    canTriggerCallback: () =>
      canTransitionLocal && canTransition && !localTransitionLocked.current,
  });

  const scrollToNewSection = (i) => {
    if (!canTransitionLocal) {
      return;
    }

    dispatch(setAnimationType('slow'));
    dispatch(setLandingIndex(i));
    lastIndex.current = i;
    dispatch(setCanTransition(false));
    heroScroll(filterRef, {
      fill: heroSlides[i].fill,
      path: heroSlides[i].path,
      x: heroSlides[i].x,
      onComplete: () => {
        dispatch(setCanTransition(true));
      },
    });
  };

  const handleSwipe = (direction) => {
    if (
      !canTransitionLocal ||
      !canTransition ||
      localTransitionLocked.current
    ) {
      return;
    }

    if (direction === 'up') {
      // scrolls down
      if (landingIndex + 1 < heroSlides.length) {
        // handle scroll down
        scrollToNewSection(landingIndex + 1);
      } else {
        if (canTransitionLocal) {
          // update
          goToNextSection();
          localTransitionLocked.current = true;
        }
      }
    } else {
      // scrolls up
      if (landingIndex - 1 >= 0) {
        // handle scroll up
        scrollToNewSection(landingIndex - 1);
      }
    }
  };

  const [touchStart, touchEnd] = useSwipe(handleSwipe, {
    threshold: 50,
    throttle: 1000,
    canTriggerCallback: () =>
      canTransitionLocal && canTransition && !localTransitionLocked.current,
  });

  return (
    <div
      ref={landingRef}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      onWheel={onWheel}
      className={styles.container}
    >
      {/* background effects */}
      <div className={`${styles.canvasWrapper}`}>
        {!breakpoints.md && !isTablet && <ShaderCanvas />}
        {/* // add fallback image to canvaas wrapper */}

        {/* masked svg */}
        {!breakpoints.md && !isTablet && <SVGFilter ref={filterRef} />}

        {(breakpoints.md || isTablet) && (
          <div
            className={`${styles.fallbackBackground} fallback-bg`}
            style={{ backgroundColor: '#E4D792' }}
          >
            <div
              className={`${styles.tabletTear} ${styles.tearBackground} tear-background`}
              style={{
                backgroundSize: tearSize,
              }}
            />
          </div>
        )}
      </div>

      <section className={styles.heroSection}>
        {/* shapes */}
        {heroSlides.map((scr, i) => (
          <HeroShape
            key={scr.name}
            in={landingIndex === i}
            imageUrl={scr.imageUrl}
            type={animationType}
          />
        ))}

        <div className={styles.heroBlockWrapper}>
          <div className={styles.heroBlockOuter} ref={heroBlockOuterRef}>
            {heroSlides.map((scr, i) => (
              <HeroText
                type={animationType}
                key={scr.name + '-text'}
                show={landingIndex === i}
                title={scr.title}
                subtitle={scr.subtitle}
                hideArrow={i === heroSlides.length - 1}
                onNextClick={() => {
                  scrollToNewSection(i + 1);
                }}
              />
            ))}
          </div>
          <div ref={sloganRef} className={styles.sloganWrapper}>
            <Slogan spin={true} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingTransition;
