import React, { useState, useRef, useEffect, useCallback } from 'react';
const useWheel = (onWheelCallback, { throttle, threshold, canTriggerCallback }) => {
  const savedCallback = useRef(onWheelCallback);
  // const latestCanTriggerCallback = useRef(canTriggerCallback);
  const currentlyThrottled = useRef(false);
  let throttleTimeout = null;

  // useEffect(() => {
  //   // sets whether the callback can be triggered,
  //   // this is to prevent other transition checks to run in conjunction with swiping
  //   // in a nutshell, it allows trigger events to only happen once all transition conditions are met
  //   // console.log('cantrigger c allback', canTriggerCallback);
  //   // latestCanTriggerCallback.current = canTriggerCallback;
  // }, [canTriggerCallback]);

  useEffect(() => {
    savedCallback.current = onWheelCallback;
  }, [onWheelCallback]);

  const handleWheel = (e) => {
    if (!currentlyThrottled.current && canTriggerCallback() && Math.abs(e.deltaY) > threshold) {
      savedCallback.current(e);
      // only if throttled
      if (throttle) {
        currentlyThrottled.current = true;
        throttleTimeout = setTimeout(() => {
          currentlyThrottled.current = false;
        }, throttle); // if none is given
      }
    }
  };

  useEffect(() => {
    return () => {
      if (throttleTimeout !== null) {
        clearTimeout(throttleTimeout);
      }
    };
  }, []);

  // expose event handler callbacks
  return [handleWheel];
};
export default useWheel;
