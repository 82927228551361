import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import { Transition } from 'react-transition-group';
import { heroSlides } from '../../../constants/heroSlides';
import useInterval from '../../../hooks/useInterval';
import useSwipe from '../../../hooks/useSwipe';
import gsap from 'gsap';

const HeroSlide = ({ fill, title, shape, description }) => {
  return (
    <div className={`${styles.heroSlide}`}>
      {/* contains the shape and the copy */}
      <div className={`${styles.heroShapeWrapper}`}>
        <img className={`${styles.heroShape} hs`} src={shape} />
      </div>
      <div className={`${styles.textWrapper}`}>
        <h3 className={`${styles.title} title-L`}>{title}</h3>
        <p className={`${styles.description} body-M`}>{description}</p>
      </div>
    </div>
  );
};

const LandingSectionMobile = ({ show }) => {
  const carouselRef = useRef();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [previousCarouselIndex, setPreviousCarouselIndex] = useState(0);
  const tearSize = 2000;
  const animationTime = 0.5;
  const carouselInterval = 100000;
  const interval = useInterval(() => {
    nextSlide();
  }, carouselInterval * 1000);

  const nextSlide = () => {
    interval.resetInterval();
    setPreviousCarouselIndex(carouselIndex);
    setCarouselIndex(
      carouselIndex + 1 < heroSlides.length ? carouselIndex + 1 : 0
    );
  };

  const previousSlide = () => {
    interval.resetInterval();
    setPreviousCarouselIndex(carouselIndex);
    setCarouselIndex(carouselIndex - 1 > 0 ? carouselIndex - 1 : 0);
  };

  useEffect(() => {
    // initialize background colour
    gsap.set(carouselRef.current, {
      backgroundColor: heroSlides[carouselIndex].fill,
    });
  }, []);

  useEffect(() => {
    // animate background color
    gsap.to(carouselRef.current, {
      duration: animationTime,
      backgroundColor: heroSlides[carouselIndex].fill,
    });

    gsap.to(carouselRef.current.querySelector('.tear-background'), {
      duration: animationTime * 1.25,
      backgroundPositionX: carouselIndex * 320 * -1,
    });
  }, [carouselIndex]);

  const [touchStart, touchEnd] = useSwipe(
    (dir) => {
      if (dir === 'right') {
        previousSlide();
      } else {
        nextSlide();
      }
    },
    {
      throttle: 1000,
      threshold: 50,
      canTriggerCallback: () => true,
      horizontal: true,
    }
  );

  return (
    <div
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      ref={carouselRef}
      className={styles.carouselWrapper}
    >
      <div
        className={`${styles.tearBackground} tear-background`}
        style={{ backgroundSize: tearSize }}
      ></div>

      <div className={`${styles.pagination}`}>
        {heroSlides.map((slide, i) => (
          <div
            key={`pagination-${i}`}
            className={`${styles.paginationItem} ${
              carouselIndex === i && styles.active
            }`}
          ></div>
        ))}
      </div>

      <div className={`${styles.slides}`}>
        {heroSlides.map((slide, i) => (
          <Transition
            key={`heroSlide-${i}`}
            timeout={animationTime * 1000}
            mountOnEnter
            unmountOnExit
            in={carouselIndex === i}
            onEnter={(node) => {
              const shapeEl = node.querySelector('.hs');
              const textEl = node.querySelectorAll('.title-L');
              const descEl = node.querySelector('.body-M');
              gsap.from([shapeEl, textEl, descEl], {
                duration: animationTime,
                x: -80 * (previousCarouselIndex < carouselIndex ? -1 : 1),
                opacity: 0,
                stagger: 0.1,
              });
            }}
            onExit={(node) => {
              const shapeEl = node.querySelector('.hs');
              const textEl = node.querySelectorAll('.title-L, .body-M');
              const descEl = node.querySelector('.body-M');
              gsap.to([shapeEl, textEl, descEl], {
                duration: animationTime,
                x: 80 * (previousCarouselIndex < carouselIndex ? -1 : 1),
                stagger: 0.1,
              });

              gsap.to([shapeEl, textEl, descEl], {
                duration: animationTime / 2,
                opacity: 0,
              });
            }}
          >
            <HeroSlide
              title={slide.title}
              description={slide.subtitle}
              shape={slide.imageUrl}
              fill={slide.fill}
            />
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default LandingSectionMobile;
