import React, { useState, forwardRef, useEffect, useRef } from 'react';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import * as styles from './index.module.scss';
import Logo from '../logo';
import Icon from '../icon';
import Chat from '../chat';
import gsap, { Cubic, Back } from 'gsap';
import { navigate } from 'gatsby-link';
import { useSelector, useDispatch } from 'react-redux';
import WipeLink from '../nav-transitions/wipe-link';
import {
  bumpJumpSection,
  setLandingIndex,
  setAnimationType,
} from '../../state/home';
import { isMobile, isTablet } from 'react-device-detect';

const SideNav = ({ sections, showInner }, ref) => {
  const dispatch = useDispatch();
  const combinedRef = useCombinedRefs(ref);
  const [sideNavEls, setSideNavEls] = useState([]);
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  const onLandingSection = useSelector((state) => state.nav.onLandingSection);
  const onCaseStudiesSection = useSelector(
    (state) => state.nav.onCaseStudiesSection
  );
  const onWhatWeDoSection = useSelector((state) => state.nav.onWhatWeDoSection);
  const landingIndex = useSelector((state) => state.home.landingIndex);
  const jumpSection = useSelector((state) => state.home.jumpSection);
  const firstUpdate = useRef(false);
  const canTransition = useSelector((state) => state.home.canTransition);

  useEffect(() => {
    initSideNavRefs();
  }, []);

  useEffect(() => {
    // landing section jump occurred, play animation
    animateJumpSectionOut();
  }, [jumpSection]);

  // if menu state changes, play animation
  useEffect(() => {
    if (onLandingSection) {
      if (menuOpen) {
        animateOutLanding();
      } else {
        animateInLanding();
      }
    }

    if (onWhatWeDoSection) {
      if (menuOpen) {
        animateOutHomeScrollable();
      } else {
        animateInHomeScrollable();
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    if (firstUpdate.current === true) {
      firstUpdate.current = false;
    } else {
      if (!showInner) {
        return;
      }

      if (onWhatWeDoSection) {
        animateOutInner();
      }
    }
  }, [onWhatWeDoSection]);

  useEffect(() => {
    if (onLandingSection) {
      animateInInner();
    } else {
    }
  }, [onLandingSection]);

  // useEffect(() => {
  //   if (onCaseStudiesSection) {
  //     animateInInner();
  //   }
  // }, [onCaseStudiesSection]);

  // ============ REFERENCES =============== //

  // stores the references in state
  const initSideNavRefs = () => {
    let elLogo = null;
    let elName = null;
    let elSectionPagination = null;
    let elInstagram = null;
    let elLinkedIn = null;
    let elFacebook = null;
    // let elChat = null;

    if (showInner) {
      elLogo = combinedRef.current.children[0];
      elName = combinedRef.current.children[1];
      elSectionPagination = combinedRef.current.children[2];
      elInstagram = combinedRef.current.children[3].children[0];
      elLinkedIn = combinedRef.current.children[3].children[1];
      elFacebook = combinedRef.current.children[3].children[2];
      // elChat = combinedRef.current.children[3].children[3];

      setSideNavEls({
        elLogo,
        elName,
        elSectionPagination,
        elInstagram,
        elLinkedIn,
        elFacebook,
        // elChat,
      });
    } else {
      // hierarchy changed
      elLogo = combinedRef.current.children[0];
      // elChat = combinedRef.current.children[1].firstChild.firstChild;
      setSideNavEls({
        elLogo,
        // elChat,
      });
    }
  };

  // reference to all nav els
  const getAllSideNavEls = (sideNavEls) => {
    return Object.values(sideNavEls);
  };

  // reference to all items but the chat
  const getLandingRefs = (sideNavEls) => {
    let temp = Object.values(sideNavEls);
    // temp.pop();
    return temp;
  };

  //  get center refs
  const getSideNavInnerRefs = (sideNavEls) => {
    let temp = Object.values(sideNavEls);
    temp.shift();
    // temp.pop();
    return temp;
  };

  //gets reference to the logo
  const getLogoRef = (sideNavEls) => {
    return sideNavEls.elLogo;
  };

  // animates the logo out
  const animateOutHomeScrollable = () => {
    gsap.to(getLogoRef(sideNavEls), {
      duration: 0.35,
      ease: Cubic.easeOut,
      x: -50,
      opacity: 0,
      onComplete: () => {
        gsap.set(getLandingRefs(sideNavEls), {
          x: -99999,
        });
      },
    });
  };

  // animates the logo back in if in scrollable
  const animateInHomeScrollable = () => {
    gsap.set(getLandingRefs(sideNavEls), {
      x: -50,
    });
    gsap.to(getLogoRef(sideNavEls), {
      duration: 0.35,
      ease: Cubic.easeOut,
      x: 0,
      opacity: 1,
    });
  };

  const animateInLanding = () => {
    const duration = 0.5;
    gsap.set(getLandingRefs(sideNavEls), {
      x: -50,
    });

    const sideNav = gsap.to(getLandingRefs(sideNavEls), {
      duration: duration,
      ease: Cubic.easeOut,
      stagger: -0.05,
      x: 0,
      opacity: 1,
    });
  };

  const animateOutLanding = () => {
    const duration = 0.5;

    gsap.to(getLandingRefs(sideNavEls), {
      duration: duration / 2,
      stagger: 0.05,
      ease: Cubic.easeIn,
      x: -50,
      opacity: 0,
      onComplete: () => {
        gsap.set(getLandingRefs(sideNavEls), {
          x: -99999,
        });
      },
    });
  };

  // when scrolling from scrollable to landing, animate in inner nav items
  const animateInInner = () => {
    gsap.to(getSideNavInnerRefs(sideNavEls), {
      onStart: () => {
        gsap.set(getSideNavInnerRefs(sideNavEls), { x: 0 });
      },
      delay: 0.3,
      duration: 0.35,
      ease: Cubic.easeOut,
      stagger: -0.05,
      y: 0,
      opacity: 1,
    });
  };

  // when scrolling from landing to scrollable, animate out inner nav items
  const animateOutInner = () => {
    gsap.to(getSideNavInnerRefs(sideNavEls), {
      duration: 0.25,
      stagger: 0.05,
      ease: Cubic.easeIn,
      y: -50,
      opacity: 0,
      onComplete: () => {
        gsap.set(getSideNavInnerRefs(sideNavEls), { x: -9000 });
      },
    });
  };

  // animates the entire nav out during landing section jump
  const animateJumpSectionOut = () => {
    if (getAllSideNavEls(sideNavEls).length === 0) {
      return;
    }
    gsap
      .timeline()
      .add(
        gsap.to(getAllSideNavEls(sideNavEls), {
          duration: 0.75,
          ease: Back.easeOut,
          x: -200,
        })
      )
      .add(
        gsap.to(getAllSideNavEls(sideNavEls), {
          duration: 0.75,
          delay: 0.15,
          ease: Back.easeInOut,
          x: 0,
        })
      );
  };

  // ============= UTILITIES ============= //

  const goToHome = () => {
    navigate('/');
  };

  const selectNewSection = (i) => {
    // only if a different one is clicked
    if (i !== landingIndex && canTransition) {
      dispatch(setAnimationType('fast'));
      dispatch(bumpJumpSection());
      dispatch(setLandingIndex(i));
    }
  };

  return (
    <div ref={combinedRef} className={styles.container}>
      <WipeLink to='/'>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </WipeLink>
      {showInner && (
        <>
          <div
            className={`${styles.titleWrapper} ${
              (isMobile || isTablet) && styles.tabletAndSmaller
            }`}
          >
            <h4 className={styles.title}> LUCKY BEARD </h4>
          </div>

          <div
            className={`${styles.sectionSelector} ${
              (isMobile || isTablet) && styles.tabletAndSmaller
            }`}
          >
            {sections.map((section, i) => {
              return (
                <div
                  key={section.name}
                  onClick={() => {
                    selectNewSection(i);
                  }}
                  className={styles.lineWrapper}
                >
                  <div
                    className={`${styles.line} ${
                      landingIndex === i ? styles.active : ''
                    }`}
                  ></div>
                </div>
              );
            })}
          </div>
        </>
      )}

      <div
        className={`${styles.social} ${
          (isMobile || isTablet) && styles.tabletAndSmaller
        }`}
      >
        {showInner && (
          <>
            <a
              href='https://www.instagram.com/luckybeardglobal'
              target='_blank'
              className={`icon-instagram ${styles.socialIcon} ${styles.instagram}`}
            ></a>
            <a
              href='https://www.linkedin.com/company/lucky-beard'
              target='_blank'
              className={`icon-linkedin ${styles.socialIcon} ${styles.linkedin}`}
            ></a>
            <a
              href='https://www.facebook.com/luckybeardglobal'
              target='_blank'
              className={`icon-facebook ${styles.socialIcon} ${styles.facebook}`}
            ></a>
          </>
        )}
        {/* <div className={styles.chatIcon}>
          <Chat />
        </div> */}
      </div>
    </div>
  );
};

export default forwardRef(SideNav);
