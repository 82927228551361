import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback,
} from 'react';
import * as styles from './index.module.scss';
import Slogan from '../../slogan';
import { Transition } from 'react-transition-group';
import { setOnCaseStudiesSection } from '../../../state/nav';
import { useDispatch, useSelector } from 'react-redux';
import CaseStudyPreview from './case-study-preview';
import { addTransition, removeTransition } from '../../../state/nav';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';
import { setCaseStudyIndex } from '../../../state/home';

import useSwipe from '../../../hooks/useSwipe';
import useWheel from '../../../hooks/useWheel';

const CaseStudiesSectionTransition = (props) => {
  const animDuration = 1;
  const { show, sectionParallaxDirection } = props;

  const activeTransitions = useSelector((state) => state.nav.activeTransitions);
  const dispatch = useDispatch();
  return (
    <Transition
      timeout={animDuration * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) =>
        sectionEnter(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('case-studies'));
          },
          onComplete: () => {
            dispatch(removeTransition('case-studies'));
          },
        })
      }
      onExit={(node) =>
        sectionExit(node, animDuration, {
          sectionParallaxDirection,
          onStart: () => {
            dispatch(addTransition('case-studies'));
          },
          onComplete: () => {
            dispatch(removeTransition('case-studies'));
          },
        })
      }
    >
      <CaseStudiesSection
        animDuration={animDuration}
        canTransition={activeTransitions.length === 0}
        {...props}
      />
    </Transition>
  );
};

const CaseStudiesSection = ({
  featuredCaseStudies,
  goToPreviousSection,
  goToNextSection,
  canTransition,
  animDuration,
}) => {
  const dispatch = useDispatch();
  const caseStudyIndex = useSelector((state) => state.home.caseStudyIndex);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [canTransitionLocal, setCanTransitionLocal] = useState(true);
  const localTransitionLocked = useRef(true);

  const onExitStart = () => {
    setCanTransitionLocal(false);
  };

  const onExitComplete = () => {
    setCanTransitionLocal(true);
  };

  const handleWheel = (event) => {
    if (
      !canTransitionLocal ||
      !canTransition ||
      localTransitionLocked.current
    ) {
      return;
    }
    if (event.deltaY > 0) {
      // scrolls down
      if (caseStudyIndex < featuredCaseStudies.length - 1) {
        setScrollDirection('up');
        dispatch(setCaseStudyIndex(caseStudyIndex + 1));
      } else {
        // go to next section
        setCanTransitionLocal(false);
        goToNextSection();

      }
    } else {
      // scrolls up
      if (caseStudyIndex === 0) {
        setCanTransitionLocal(false);
        goToPreviousSection();
      } else {
        setScrollDirection('down');
        dispatch(setCaseStudyIndex(caseStudyIndex - 1));
      }
    }
  };

  const [onWheel] = useWheel(handleWheel, {
    threshold: 10,
    throttle: 1000,
    canTriggerCallback: () =>
      canTransitionLocal && canTransition && !localTransitionLocked.current,
  });

  const handleSwipe = (direction) => {
    if (
      !canTransitionLocal ||
      !canTransition ||
      localTransitionLocked.current
    ) {
      return;
    }

    if (direction === 'up') {
      // scrolls down
      if (caseStudyIndex < featuredCaseStudies.length - 1) {
        setScrollDirection('up');
        dispatch(setCaseStudyIndex(caseStudyIndex + 1));
      } else {
        // go to next section
        setCanTransitionLocal(false);
        goToNextSection();

      }
    } else {
      // scrolls up
      if (caseStudyIndex === 0) {
        setCanTransitionLocal(false);
        goToPreviousSection();
      } else {
        setScrollDirection('down');
        dispatch(setCaseStudyIndex(caseStudyIndex - 1));
      }
    }
  };

  const [touchStart, touchEnd] = useSwipe(handleSwipe, {
    threshold: 50,
    throttle: 1000,
    canTriggerCallback: () =>
      canTransitionLocal && canTransition && !localTransitionLocked.current,
  });

  useEffect(() => {
    dispatch(setOnCaseStudiesSection(true));
    const transitionLocked = setTimeout(() => {
      localTransitionLocked.current = false;
    }, animDuration * 1000 + 50);
    return () => {
      clearTimeout(transitionLocked);
      dispatch(setOnCaseStudiesSection(false));
    };
  }, []);

  return (
    <div
      onWheel={onWheel}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      className={styles.caseStudiesWrapper}
    >
      {featuredCaseStudies.map((cs, i) => (
        <CaseStudyPreview
          fixed={true}
          scrollDirection={scrollDirection}
          show={i === caseStudyIndex}
          caseStudy={cs}
          key={cs.attributes.title}
          onExitStart={onExitStart}
          onExitComplete={onExitComplete}
        />
      ))}
    </div>
  );
};

export default CaseStudiesSectionTransition;
