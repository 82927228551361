import React, { useEffect, useRef, useState } from 'react';
import * as styles from './index.module.scss';
import SideNav from '../components/sidenav';
import TopNav from '../components/topnav';
import AwwwardsRibbon from '../components/awwwards-ribbon';
import Menu from '../components/topnav/menu';
import { heroSlides } from '../constants/heroSlides';
import MouseFollower from '../components/mouse-follower';
import useChatBot from '../hooks/useChatBot';
import ScrollDownIndicator from '../components/scroll-down-indicator';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useSelector } from 'react-redux';
import Preloader from '../components/preloader';
import TypeformChat from '../components/typeform-chat';
import Lightsaber from '../components/lightsaber';

const Layout = ({ children, hideLogo }) => {
  const contentRef = useRef();
  const breakpoints = useBreakpoint();
  const chatBotRef = useChatBot();
  const showPreloader = useSelector(state => state.preloading.isLoading || false);
  return (
    <div className={styles.layout}>
      {!breakpoints.sm && <ScrollDownIndicator />}
      <div ref={contentRef} className={styles.layoutContent}>
        <TopNav hideLogo={hideLogo} />
        <SideNav showInner={true} sections={heroSlides} />
        <Menu />

        <div className={styles.layoutContainer}>{children}</div>
      </div>
      {/* <div ref={swiperRef} className={styles.swiper} /> */}
      {/* <MouseFollower /> */}
      <TypeformChat />
      {showPreloader && <Preloader />}
      {/* <AwwwardsRibbon option={2}/> */}
      {/* <Lightsaber /> */}
    </div>
  );
};

export default Layout;
