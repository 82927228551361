// extracted by mini-css-extract-plugin
export var caseStudiesWrapper = "mobile-module--case-studies-wrapper--5g4Tr";
export var mobile = "mobile-module--mobile--YoIsj";
export var caseStudyPreviewWrapper = "mobile-module--case-study-preview-wrapper--LE4Fx";
export var contentWrapper = "mobile-module--content-wrapper--YHZ7E";
export var content = "mobile-module--content--HZazT";
export var title = "mobile-module--title--Wbdii";
export var description = "mobile-module--description--zejvZ";
export var viewCaseStudy = "mobile-module--view-case-study--TLpZj";
export var weDidBlock = "mobile-module--we-did-block--ERhLf";
export var row = "mobile-module--row--B1wAh";
export var column = "mobile-module--column--38RWU";
export var imageBlock = "mobile-module--image-block--OPeLb";
export var imageContainer = "mobile-module--image-container--+LHOv";
export var sloganWrapper = "mobile-module--slogan-wrapper--oADYT";
export var squigglyWrapper = "mobile-module--squiggly-wrapper--DPLYJ";