import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import SloganMantra from '../../slogan-mantra';
import Dropdown from '../../dropdown';
import gsap from 'gsap';
import { TransitionGroup, Transition } from 'react-transition-group';
import { Samy, SvgProxy } from 'react-samy-svg';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
SwiperCore.use([Autoplay]);

const OurClientsSectionMobile = ({ testimonies }, ref) => {
  const [activeFilter, setActiveFilter] = useState('');
  const [filteredTestimonies, setFilteredTestimonies] = useState(testimonies);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef();
  const swiperInstance = useRef();
  const spaces = [
    'All',
    ...Array.from(new Set(testimonies.map((t) => t.space))),
  ]; // get unique list of spaces

  useEffect(() => {
    if (testimonies.length > 0) {
      setActiveFilter('All');
    }
  }, [testimonies]);

  const changeSlide = (slide) => {
    swiperInstance.current.slideTo(slide);
  };

  const onFilterChange = (value) => {
    setActiveFilter(value);
  };

  const offsetSlider = (swiper) => {
    gsap.to(swiperRef.current.firstChild, {
      marginLeft: swiper.slidesGrid[0],
      duration: 0.0001,
    });
  };

  useEffect(() => {
    setFilteredTestimonies(
      testimonies.filter((t) => {
        if (activeFilter === 'All') {
          return true;
        } else if (t.space === activeFilter) {
          return true;
        } else {
          return false;
        }
      })
    );

    if (swiperInstance.current) {
      swiperInstance.current.slideTo(0);
    }
  }, [activeFilter]);

  const sliderSettings = {
    slidesPerView: 'auto',
    spaceBetween: 35,
    centeredSlides: true,
    grabCursor: true,
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    onSlideChange: (e) => {
      setActiveIndex(e.realIndex);
    },
  };

  const timeoutDuration = 0.35;
  const animationDuration = timeoutDuration * 0.7; // 70% animation, the rest is staggered delay
  const delayDuration = timeoutDuration - animationDuration; // calculates delay time

  return (
    activeFilter !== '' && (
      <div ref={ref} className={`${styles.ourClientsWrapper} ${styles.mobile}`}>
        <div className={styles.topContainer}>
          <div className={styles.gradientTextWrapper}>
            <p className={`title-M semi-bold ${styles.featureText}`}>
              {/* {' '}
            Their quality, breadth of thinking and design work is outstanding{' '} */}
              The brands we've worked with
            </p>
            {/* <GradientText
      customClass={styles.gradientCustom}
      text={
        'Their quality, breadth of thinking and design work is outstanding'
      }
    /> */}
          </div>

          <div className={styles.smWrapper}>
            <SloganMantra hideDivider={true} />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.bottomContainer}>
          <div className={styles.dropdownWrapper}>
            <Dropdown
              options={spaces}
              fullWidth={true}
              autoHide={false}
              onChange={onFilterChange}
            />
          </div>
          <div className={styles.testimonyCarousel}>
            <div className={styles.swiperWrapper}>
              <Swiper
                onInit={(val) => {
                  swiperInstance.current = val;
                }}
                onResize={offsetSlider}
                onSwiper={offsetSlider}
                ref={swiperRef}
                {...sliderSettings}
              >
                {filteredTestimonies.length > 0 &&
                  filteredTestimonies.map((t, i) => (
                    <SwiperSlide
                      key={t.name}
                      onClick={() => {
                        changeSlide(i);
                      }}
                      className={`${styles.swiperSlide}`}
                    >
                      <div
                        className={`${styles.testimonyBlockWrapper} ${
                          activeIndex === i && styles.active
                        } `}
                      >
                        <div
                          className={`${styles.testimonyBlock} ${
                            activeIndex === i && styles.active
                          }`}
                          style={{
                            background: t.brandColour,
                          }}
                        >
                          <div className={styles.logoWrapper}>
                            <Samy svgXML={t.logo}>
                              <SvgProxy
                                selector={'svg'}
                                width={'100%'}
                                height={'100%'}
                                style={{ maxWidth: 200 }}
                              />
                              <SvgProxy
                                selector={'path'}
                                fill={activeIndex === i ? '#FFF' : '#000'}
                              />
                            </Samy>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className={`${styles.carouselPagination}`}>
              <div
                className={`${styles.paginationItem}`}
                style={{
                  transform: `translateX(${
                    activeIndex * (315 / filteredTestimonies.length)
                  }px)`,
                  width: filteredTestimonies.length
                    ? 315 / filteredTestimonies.length
                    : 0,
                }}
              ></div>
            </div>
          </div>
        </div>{' '}
      </div>
    )
  );
};

export default forwardRef(OurClientsSectionMobile);
