import React, { forwardRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import * as styles from './index.module.scss';
import gsap, { Power2, Back } from 'gsap';
import { isTablet } from 'react-device-detect';
import ArrowRight from '../arrow-right';
const HeroText = (
  { show, title, subtitle, type, onNextClick, hideArrow },
  ref
) => {
  const animDuration = 0.15;
  const delay = 1.22;

  return (
    <Transition
      timeout={(animDuration + delay) * 1000}
      mountOnEnter
      unmountOnExit
      in={show}
      onEnter={(node) => {
        if (type === 'slow') {
          gsap.from(node, {
            duration: 0.5,
            delay: 0.3,
            opacity: 0,
            y: 20,
          });
        } else {
          gsap.from(node, {
            duration: animDuration,
            opacity: 0,
            x: -10,
            scaleX: 1.2,
            delay,
          });
        }
      }}
      onExit={(node) => {
        if (type === 'slow') {
          gsap.to(node, {
            duration: 0.5,
            opacity: 0,
          });
        } else {
          gsap.to(node, {
            duration: animDuration,
            opacity: 0,
            x: -10,
            delay,
          });
        }
      }}
    >
      <div ref={ref} className={`${styles.heroBlock} ${isTablet && styles.isTablet}`}>
        <h1 className={'title-L semi-bold'}>{title}</h1>
        <h4 className={'body-L'}>{subtitle}</h4>
        {!hideArrow && (
          <ArrowRight
            onClick={() => {
              onNextClick();
            }}
          />
        )}
      </div>
    </Transition>
  );
};

export default forwardRef(HeroText);
