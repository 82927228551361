import React, { useEffect, useState, useRef, forwardRef } from "react";
import * as styles from "./index.module.scss";
import { debounce } from "lodash";
import gsap from "gsap";
const GradientText = ({ text, customClass }, ref) => {
  const patternRef = useRef();
  const textRef = useRef();

  const handleMouse = (e) => {
    const mouseMultiplier = 40;
    if (patternRef.current !== null) {
      const percentage =
        (e.pageX / window.innerWidth + -0.5) * mouseMultiplier -
        (e.pageY / window.innerHeight + -0.5) * mouseMultiplier;
      const degrees =
        (e.pageY / window.innerHeight) * (e.pageX / window.innerWidth) * 90;

      if (textRef.current !== null) {
        gsap.to(textRef.current, {
          duration: 0.5,
          backgroundImage: `
            linear-gradient(
              ${degrees}deg,
              #542c66 ${0 + percentage}%,
              #7bcaa8 ${20 + percentage}%,
              #eb45b5 ${40 + percentage}%,
              #ea3578 ${60 + percentage}%,
              #ea3bb3 ${80 + percentage}%,
              #0026f5 ${100 + percentage}%
              )`,
        });
      }
    }
  };
  const debounced = debounce(handleMouse, 100, { maxWait: 100 });
  useEffect(() => {
    document.addEventListener("mousemove", debounced);
    return () => {
      debounced.cancel();
      document.removeEventListener("mousemove", debounced);
    };
  }, []);
  return (
    <div ref={ref} className={styles.gradientTextWrapper}>
      <h2
        ref={textRef}
        className={`${customClass && customClass} ${styles.textGradient}`}
      >
        {text}
      </h2>
    </div>
  );
};

export default forwardRef(GradientText);
