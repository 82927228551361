import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import Slogan from '../../slogan';
import GradientText from '../../gradient-text';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  sectionEnter,
  sectionExit,
} from '../../animate/landing-section-transitions';

const WhatWeDoSectionMobile = ({}, ref) => {
  const breakpoints = useBreakpoint();
  const [wwdList] = useState([
    {
      title: 'Business design',
      text: 'We help your business discover new opportunities for growth',
      link: '',
    },
    {
      title: 'Brand design',
      text: 'We help you build brands people can believe in',
      link: '',
    },
    {
      title: 'Product design',
      text: 'We help you build products people will fall in love with ',
      link: '',
    },
    {
      title: 'Experience design',
      text: 'We help you create joyful, delightful experiences',
      link: '',
    },
  ]);
  const weWorkText =
    'We work with restless leadership teams to help build businesses, brands, products and experiences for the customers of tomorrow';
  return (
    <div ref={ref} className={`${styles.whatWeDoWrapper} ${styles.mobile}`}>
      <div className={styles.innerLeft}>
        <div className={styles.sloganWrapper}>
          <Slogan width={breakpoints.md && 135} spin={true} />
        </div>
        <GradientText text={weWorkText} customClass={styles.customText} />
      </div>
      <div className={styles.innerRight}>
        {wwdList.map((item, i) => (
          <div key={`wwd-${i}`} className={`${styles.wwdType}`}>
            <h3 className={`title-XS bold`}>{item.title}</h3>
            <p className={`body-M`}>{item.text} </p>

            {/* <ArrowRight darkColor={true} onClick={() => {}} /> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default forwardRef(WhatWeDoSectionMobile);
