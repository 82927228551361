import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './mobile.module.scss';
import Slogan from '../../slogan';
import Squiggly from '../../squiggly';
import WipeLink from '../../nav-transitions/wipe-link';
import ButtonLink from '../../button/button-link';

const CaseStudiesSectionMobile = ({ featuredCaseStudies }, ref) => {
  return (
    <div ref={ref} className={`${styles.caseStudiesWrapper} ${styles.mobile}`}>
      {featuredCaseStudies.map((cs, i) => (
        <div key={`cs-${i}`} className={styles.caseStudyPreviewWrapper}>
          <div className={styles.imageBlock}>
            <div
              className={`${styles.imageContainer}`}
              style={{
                backgroundImage: `url(${cs.attributes.featureImage.data.attributes.url})`,
              }}
            >
              <div className={styles.squigglyWrapper}>
                <Squiggly />
              </div>
              <div className={styles.sloganWrapper}>
                <Slogan />
              </div>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <h2 className={`title-S ${styles.title}`}>
                {' '}
                {cs.attributes.title}{' '}
              </h2>
              <p className={`body-S ${styles.description}`}>
                {cs.attributes.description}{' '}
              </p>
              <WipeLink to={`/our-work/${cs.attributes.slug}`}>
                <ButtonLink text='View Case Study' />
              </WipeLink>

              <div className={styles.weDidBlock}>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <h4 className={'utility-overline-heavy'}>WE DID</h4>
                    <p className={'body-S'}>
                      {cs.attributes.case_study_works.data
                        .map((csw) => csw.attributes.title)
                        .join(', ')}
                    </p>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <h4 className={'utility-overline-heavy'}>CATEGORY</h4>
                    <p className={'body-S'}>
                      {cs.attributes.case_study_category.data.attributes.title}
                    </p>
                  </div>
                  <div className={styles.column}>
                    <h4 className={'utility-overline-heavy'}>TIMEFRAME</h4>
                    <p className={'body-S'}>{cs.attributes.roadmap}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default forwardRef(CaseStudiesSectionMobile);
