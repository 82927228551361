import React from 'react';
import gsap, { Back, Linear } from 'gsap';
import { getRandomIntBetween } from '../../helpers/helpers';
export const heroIn = (filterRef, heroBlockRef, sloganRef, options) => {
  const pathElement = filterRef.current.querySelector('#Tear');

  // change svg mask colour
  gsap.to(filterRef.current.lastChild, {
    delay: 1.22,
    duration: 0.15,
    fill: options.fill,
    ease: Linear.easeInOut,
  });

  const openTears = [
    'M522.512 1C522.512 1 433.731 35.8326 428.447 102.674C426.333 131.858 481.292 109.264 483.406 160.1C485.52 210.937 477.065 216.586 450.642 228.824C424.219 241.063 439.016 262.715 441.13 277.778C443.243 292.841 484.463 313.552 467.552 337.088C453.813 358.741 464.382 364.389 444.3 386.042C424.219 407.695 406.354 406.46 390.5 446C376.76 483.657 429.606 478.301 390.5 558.322C357.736 606.335 346.008 582.799 318.528 607.276C302.674 622.339 314.3 650.582 305.845 672.234C296.333 695.77 319.585 708.95 344.951 718.364C371.374 727.778 393.569 744.724 407.308 767.318C410.479 774.849 407.308 787.088 379.829 802.151C349.178 819.096 312.187 832.276 336.495 841.69C349.178 846.398 362.918 867.109 365.032 881.23C367.146 896.293 400.967 901 400.967 901H2.51172C2.51172 901 16.2516 889.703 27.8776 864.285C45.8451 842.632 71.2109 843.573 74.3816 830.393C80.7231 810.623 54.3003 822.862 56.4142 763.552C57.4711 724.013 5.68245 729.661 6.73936 708.95C8.85318 689.18 8.85318 687.27 6.73936 667.5C3.56863 652.437 17.1224 647.5 32 637C46.8776 626.5 59.3537 594.414 43.5 585C25.5325 574.644 45.6138 566.795 43.5 558.322C46.6707 522.5 69.0971 532.36 79.6662 506C81.78 499.41 103.512 478.071 94 461C79.2033 434.445 40.5605 431.23 47.9589 417.109C55.3572 402.987 3.56863 398.28 25.7637 365.331C50.0727 336.146 124.056 329.557 140.967 308.845C159.991 288.134 106.089 262.715 129.341 243.887C152.593 225.059 98.6906 229.766 64.8694 223.176C38.4467 217.527 14.1377 202.464 36.3329 181.753C58.528 161.042 79.6662 156.335 89.1784 145.979C98.6906 135.623 110.317 125.268 110.317 118.678C110.317 110.205 107.146 101.732 99.7475 96.0837C86.0076 84.7866 86.0076 68.7824 74.3816 64.0753C62.7556 59.3682 86.0077 43.364 72.2678 29.2427C53.2434 10.4142 37.3898 1 37.3898 1H522.512Z',
    'M521.012 1C521.012 1 472.571 22.585 472.571 115.494C470.593 144.587 495.308 107.986 497.285 159.603C499.263 211.219 478.502 215.911 453.787 229.05C429.073 241.25 442.913 262.835 444.89 277.851C446.867 292.867 441.924 307.882 426.107 332.283C412.266 353.868 467.5 413.237 439.947 444.9C396.35 495 437.97 547.194 359.871 582.856C255.08 628.842 364.5 647.5 348.996 673.888C335 692.5 418.198 709.551 410.289 729.259C402.381 748.967 430.061 750.844 418.198 768.675C406.335 786.506 406.335 789.321 384.586 785.567C339.111 772.429 352.951 801.521 329.225 817.476C309.453 823.106 308.464 827.799 275.841 845.63C254.092 865.338 274.852 870.03 298.578 880.354C310.441 885.046 322.305 888.8 335.156 890.677C353.939 893.492 372.723 900.062 391.506 901H1.01172C1.01172 901 37.5897 890.677 49.4528 876.6C66.2589 855.015 85.0421 847.507 88.0079 834.368C93.9395 814.66 112.723 810.906 114.7 751.782C115.689 712.366 29.6809 727.382 30.6695 706.735C30.6695 697.35 64.2817 674.827 60.3273 656.996C57.3615 641.98 78.122 622.272 71.2018 611.949C53 587 142.5 584 163.141 537.809C173.027 517.163 175.004 482.439 152.266 477.747C112.723 475.87 62.0715 525.828 45.4984 500.27C34.6239 483.5 51.5684 469.016 57.5 454C65.4087 437.107 17.8178 418.623 34.6239 382.022C59.3387 348.237 97 410.5 114.7 379.207C124.5 351 165.118 345.421 181.924 323.836C198.73 302.251 135.46 277.851 127.552 259.081C118.669 238 114.7 220.604 119.643 211.219C130.517 190.572 149.301 183.065 141.392 165.234C130.517 139.895 131.506 133.325 140.403 123.941C149.301 114.556 171.05 116.433 171.05 93.9093C172.038 84.5245 167.095 75.1397 160.175 69.5089C149.301 58.2471 101.848 102.356 91.9623 72.3243C84.0535 48.8624 116.677 63.878 109.757 34.7852C105.803 17.8926 59.3387 1 59.3387 1H521.012Z',
    'M523.24 1.93848C523.24 1.93848 466.028 23.5235 472.505 119.248C470.346 148.341 496.253 111.74 498.412 163.357C500.571 214.973 478.982 219.665 453.074 232.804C426.087 245.004 441.2 266.589 443.359 281.605C445.518 296.62 440.12 311.636 423.928 336.037C409.895 357.622 482.22 383.899 462.79 405.484C443.359 427.069 432.564 445.838 417.451 485.254C403.418 522.794 374.272 489.947 336.491 550.009C305.186 597.872 372.113 606.318 363.478 626.026C354.842 645.734 331.093 637.288 318.14 655.119C305.186 672.95 309.504 755.536 309.504 772.429C310.583 798.706 293.312 800.583 304.106 822.168C311.663 836.245 323.537 848.445 337.57 857.83C353.762 869.092 372.113 878.477 391.544 885.046C403.418 889.738 416.372 893.492 430.405 895.369C449.836 898.185 488.697 901 488.697 901H10.4885C10.4885 901 40.7139 895.369 54.7471 890.677C100.085 879.415 105.483 882.23 113.039 856.892C116.277 809.968 75.2572 821.229 78.4956 763.982C79.5751 724.566 -5.70363 775.244 4.01166 722.689C4.01166 713.304 16.9654 690.781 12.6475 672.95C9.40905 657.934 59.065 627.903 53.6676 617.58C48.2702 607.257 59.065 592.241 43.9523 581.918C26.6807 571.594 30.9986 574.41 28.8396 565.964C27.7602 559.394 27.7602 545.317 38.5549 519.04C40.7139 512.47 21.2833 482.439 29.9191 464.608C38.5549 446.777 63.3829 468.362 83.893 436.454C103.324 406.422 75.2572 423.315 114.118 376.391C141.105 324.775 54.7471 336.037 72.0187 314.452C89.2904 292.867 40.7139 259.081 64.4624 240.312C88.2109 221.542 55.8266 219.665 66.6213 207.465C89.2904 184.003 30 180.5 45.0318 160.541C51.651 151.752 72.0187 127.694 87.1314 124.879C114.118 119.248 142.841 116.5 142.185 97.6632C141.528 78.8264 122.754 74.2013 96.8467 76.0782C42.8728 78.8936 24.5217 72.3243 34.237 57.3087C40.7139 44.17 80.6546 61.0626 75.2572 35.7237C76.3366 26.3389 7.25009 1 7.25009 1H523.24V1.93848Z',
    'M521 1C521 1 421.977 35.7962 419.006 87.5204C416.035 127.019 507.137 106.329 496.244 155.232C484.361 202.254 461.586 194.73 435.84 207.897C410.094 220.122 412.074 238.931 414.055 253.978C416.035 269.025 400.191 295.357 384.348 319.809C370.484 341.439 388.309 406.329 369.494 427.019C350.68 448.649 311.07 455.232 295.226 494.73C281.363 532.348 330.875 559.621 321.963 573.727C314.041 585.953 363.553 615.107 355.631 634.856C347.709 654.605 360.582 656.486 369.494 676.235C377.416 693.163 374.445 701.627 375.435 718.555C376.426 744.887 365.533 747.708 375.435 769.339C378.406 776.862 382.367 796.611 355.631 808.837C323.943 832.348 308.1 844.574 330.875 853.978C343.748 858.68 337.807 883.132 357.611 886.893C387.318 892.536 401.182 901 401.182 901H22.9118C22.9118 901 46.6775 886.893 54.5993 877.489C71.4333 855.859 94.2087 842.693 97.1794 829.527C103.121 809.777 99.1599 796.611 78.365 747.708C64.5017 716.674 32.8142 716.674 34.7946 689.401C35.7849 673.414 34.7946 657.426 32.8142 641.439C29.8435 626.392 7.06806 627.332 1.12665 601.94C-0.853824 585.953 20.9313 597.238 28.8532 582.191C29.8435 572.787 28.8532 563.382 24.8923 554.919C23.9021 548.335 17.9606 548.335 29.8435 522.003C31.8239 515.42 55.5896 476.862 65.4919 458.994C72.4236 443.006 74.404 426.078 71.4333 409.15C66.4822 385.64 57.57 384.699 64.5017 369.652C75.3943 348.962 76.3845 324.511 68.4626 301.94C60.5408 274.668 70.4431 256.799 93.2185 237.991C106.092 228.586 116.984 216.36 124.906 203.194C136.789 188.147 149.662 173.1 164.515 159.934C187.291 139.245 176.398 122.317 169.467 115.734C159.564 107.27 118.383 119.524 106.5 112C95.8889 103.85 84.5 94 77.3747 80.9373C70 64 148.672 57.4263 152.633 43.3197C155.603 32.9749 143.72 21.6897 122.926 19.8088C102.131 17.9279 51.6286 1 51.6286 1H521Z',
    'M523.512 1C523.512 1 466.705 8.51566 461.243 76.1566C459.058 105.28 498.386 93.0668 500.57 144.737C502.755 196.407 481.999 203.923 456.873 216.136C431.747 228.349 445.949 249.956 448.134 264.987C450.318 280.019 444.856 295.05 429.562 319.476C416.453 341.083 474.352 378.662 455.781 400.269C437.209 421.877 472.167 460.395 457.965 499.852C445.949 528.035 431.747 554.34 413.176 579.706C405.528 591.919 406.621 603.192 400.066 622.921C392.419 642.649 369.478 634.194 356.369 652.044C343.26 669.894 361.831 699.956 362.923 716.866C364.016 743.171 347.629 745.05 357.461 766.658C364.016 780.749 382.298 776.605 396.5 786C417.256 800.092 384.5 824.332 384.5 845C384.5 873.02 401.066 876.5 422.5 890C434.5 897.558 459.058 901 459.058 901H3.51172C3.51172 901 43.9319 897.189 64 875C84.0681 852.811 87.6294 840.652 90.9067 827.5C97.7591 800 58.1336 802.357 60.3184 744.111C61.4109 704.653 50.4865 709.351 51.5789 688.683C50.4865 677.409 40.1439 661 41 642C42.5681 607.198 82.5 642.5 90.9067 611.647C94 591.5 88.7218 573.129 74.5201 563.735C58.1336 553.401 46.5 535.5 39.5621 527.096C34.5 517.5 16.621 501.731 27.5453 474.486C29.7302 467.91 42.8394 437.848 51.5789 419.998C60.3184 402.148 79.9823 375.843 70.1504 359.873C60.3184 347.66 41.747 348.599 48.3016 334.507C53.7638 324.173 39.5621 271.564 55.9487 249.956C72.3352 228.349 45.0243 231.167 65.7806 212.378C86.5369 193.589 66.8731 191.71 67.9655 185.134C73.4277 162.587 125.865 174.8 146.621 153.192C167.377 131.585 153.176 119.372 140.066 113.735C128.05 107.159 84.3521 103.401 84.3521 95.8852C84.3521 88.3695 102.923 87.4301 91.9991 75.2171C81.0747 63.0042 62.5033 59.2463 57.0411 48.9123C48.3016 32.0021 89.8142 33.881 73.4277 17.9102C66.8731 13.2129 28.6378 1 28.6378 1H523.512Z',
    'M525.312 1C525.312 1 479.222 45.1545 474.494 112.795C472.131 141.919 498.131 105.28 500.494 156.95C502.858 208.62 480.403 213.317 453.222 226.47C426.04 238.683 441.403 260.29 443.767 275.322C446.131 290.353 440.222 305.384 423.676 329.81C409.494 351.418 353.949 388.996 333.858 410.603C313.767 432.211 244.04 451.939 228.676 491.397C213.312 527.096 231.04 597.555 221.585 611.647C210.949 631.376 200.313 651.104 192.04 670.833C183.767 690.562 158.949 682.106 144.767 699.956C130.585 717.806 150.676 747.868 151.858 764.779C153.04 791.083 135.313 792.962 145.949 814.57C153.04 828.662 164.858 840.875 180.222 850.269C196.767 861.543 215.676 870.937 234.585 877.514C246.403 882.211 263.383 888.096 283 893C307 899 332.676 901 332.676 901H5.3125C5.3125 901 28.9298 897.5 40 885.5C53 871.408 59.6761 848.39 64.4034 831.48C70.3125 811.752 91.5852 807.994 93.9489 748.808C95.1307 709.351 53.767 722.503 54.9489 701.835C54.9489 692.44 67.9489 669.894 64.4034 652.044C60.8579 637.013 56.1307 622.921 49.0398 608.829C41.9489 598.495 54.9489 583.463 39.5852 573.129C21.858 562.795 23.8636 558.955 21.5 550.5C20.3182 534.529 26.5852 525.217 33.6761 510.186C36.0398 503.61 50.2216 473.547 60.858 455.697C70.3125 437.848 75.0398 432.211 82.1307 418.119C89.2216 404.027 89.2216 383.359 96.3125 369.267C102.222 358.933 151.858 316.658 169.585 295.99C187.312 275.321 128.222 246.198 151.858 227.409C175.494 208.62 151.858 209 154.222 194.528C157 183.5 174.312 168.223 187.312 156.01C210.949 135.342 219.222 130.645 229.858 120.311C239.313 110.916 251.5 97.5762 251.5 91C251.5 82.5449 248.5 75.2928 240.494 70.5198C221.585 59.2463 204 46.1969 183.767 38.5783C161.313 30.1232 138.858 24.4864 114.04 19.7891C92.7671 16.0313 33.6761 1 33.6761 1H525.312Z',
  ];

  // animate svg shape
  gsap
    .timeline()
    .add(
      gsap.to(pathElement, {
        duration: 0.75,
        x: 0,
        ease: Back.easeOut,
        attr: {
          d: openTears[getRandomIntBetween(0, openTears.length)],
        },
      })
    )
    .add(
      gsap.to(pathElement, {
        delay: 0.15,
        duration: 0.75,
        x: options.x,
        ease: Back.easeInOut,
        attr: {
          d: options.path,
        },
      })
    );

  // animates the right section of the page out
  const rightSideElements = [heroBlockRef.current];
  gsap
    .timeline()
    .add(
      gsap.to(rightSideElements, { duration: 0.75, ease: Back.easeOut, x: 200 })
    )
    .add(
      gsap.to(rightSideElements, {
        duration: 0.75,
        delay: 0.15,
        ease: Back.easeInOut,
        x: 0,
      })
    );

  const leftSideElements = [sloganRef.current];

  gsap
    .timeline({ onComplete: options.onComplete })
    .add(
      gsap.to(leftSideElements, { duration: 0.75, ease: Back.easeOut, x: -200 })
    )
    .add(
      gsap.to(leftSideElements, {
        duration: 0.75,
        delay: 0.15,
        ease: Back.easeInOut,
        x: 0,
      })
    );
};

export const heroScroll = (filterRef, options) => {
  if (!filterRef.current) {
    setTimeout(() => {
      options.onComplete();
    }, 1500);
    return;
  }

  const pathElement = filterRef.current.querySelector('#Tear');
  // change svg mask colour
  const tl = gsap.timeline({ onComplete: options.onComplete });

  const rectColourTween = gsap.to(filterRef.current.lastChild, {
    duration: 1.5,
    fill: options.fill,
    ease: Linear.easeInOut,
  });

  const svgPathTween = gsap.to(pathElement, {
    duration: 1.5,
    x: options.x,
    attr: {
      d: options.path,
    },
  });

  tl.add(rectColourTween, 0);
  tl.add(svgPathTween, 0);
};
