import React, { useEffect, useState, useRef } from 'react';
import * as styles from './index.module.scss';

const ArrowRight = ({ onClick, darkColor, color }) => {

  let customColor = {};
  if (color) {
    customColor.backgroundColor = color;
  }
  return (
    <div
      className={styles.arrowWrapper}
      onClick={() => {
        onClick();
      }}
    >
      <div style={customColor} className={`${styles.mainLine} ${darkColor ? styles.dark : ''}`}>
        <div style={customColor} className={styles.arrowTop} />
        <div style={customColor} className={styles.arrowBottom} />
      </div>
    </div>
  );
};

export default ArrowRight;
